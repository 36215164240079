import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Projects from '../../components/Projects'
import parallastic from '../../utils/parallastic'
import './Creando.sass'

export class Creando extends React.Component {
  static propTypes = {
    pageName: PropTypes.string
  }

  static defaultProps = {
    pageName: 'Creando'
  }

  componentDidMount = () => {
    const p = parallastic(this.scrollTarget())
    p.addToggler(this.refs.mobile, 'into-view', 0.40)
    p.addToggler(this.refs.social, 'into-view', 0.40)
    p.addToggler(this.refs.speaking, 'into-view', 0.40)

    p.addToggler(this.refs.awards, 'into-view', 0.30)
    p.addToggler(this.refs.section, 'into-view', 0.25)
  }

  scrollTarget = () =>
    this.refs.creando ? this.refs.creando.parentNode.parentNode : false

  render = () => {
    return (
      <div className="page" id={this.props.pageName} ref="creando">
        <section id="intro">
          <div className="container">
            <h1 className="page-title">
              {this.props.pageName} sabores
            </h1>
            <div className="intro-text">
              <p>
                <FormattedMessage id="creando.intro-text-1" />
              </p>
              <p>
                <FormattedMessage id="creando.intro-text-2" />
              </p>
            </div>
          </div>
        </section>
        <section className="features" id="feature-1">
          <div className="left">
            <h2 className="title">
              <FormattedMessage id="creando.feature-1-title" />
            </h2>
            <p>
              <FormattedMessage id="creando.feature-1-text" />
            </p>
          </div>
          <div className="right" ref="mobile" />
        </section>
        <section className="features" id="feature-2">
          <div className="container">
            <h2 className="title">
              <FormattedMessage id="creando.feature-2-title" />
            </h2>
            <p>
              <FormattedMessage id="creando.feature-2-text" />
            </p>
          </div>
        </section>
        <section className="features" id="feature-3" ref="section">
          <div className="container">
            <div className="left">
              <div className="feature-3-icon" ref="social" />
              <h3 className="feature-3-title-1">
                <FormattedMessage id="creando.feature-3-title-1" />
              </h3>
              <p>
                <FormattedMessage id="creando.feature-3-text-1" />
              </p>
            </div>
            <div className="right">
              <div className="feature-3-icon" ref="speaking" />
              <h3 className="feature-3-title-2">
                <FormattedMessage id="creando.feature-3-title-2" />
              </h3>
              <p>
                <FormattedMessage id="creando.feature-3-text-2" />
              </p>
            </div>
          </div>
        </section>
        <section className="features" id="feature-4">
          <div className="container">
            <h2 className="title">
              <FormattedMessage id="creando.feature-4-title" />
            </h2>
            <ul>
              <FormattedMessage id="creando.feature-4-text" />
              <li>
                <FormattedMessage id="creando.feature-4-award-1" />
              </li>
              <li>
                <FormattedMessage id="creando.feature-4-award-2" />
              </li>
              <li>
                <FormattedMessage id="creando.feature-4-award-3" />
              </li>
            </ul>
          </div>
          <div className="awards" ref="awards">
            <div className="container">
              <div className="awwwards" />
              <div className="laus" />
              <div className="designpreis" />
            </div>
          </div>
        </section>
        <Projects prev="lista_robinson" next="hearthy" />
      </div>
    )
  }
}

export default Creando
