import PropTypes from 'prop-types'
import React from 'react'
import { CSSTransitionGroup } from 'react-transition-group'
import './StickToTop.sass'

export class StickToTop extends React.Component {

  static propTypes = {
    children: PropTypes.element.isRequired,
    alt: PropTypes.element,
    scrollTarget: PropTypes.func,
    offset: PropTypes.number
  }

  static defaultProps = {
    offset: 0,
    scrollTarget: () => window
  }

  shouldStick = () => {
    const elem = this.props.scrollTarget()
    if (!elem) return false
    return elem.scrollTop >= this.refs.sticker.offsetTop - this.props.offset
  }

  handleScroll = (e) => {
    const shouldStick = this.shouldStick()
    if ((this.isStuck && !shouldStick) || (!this.isStuck && shouldStick)) {
      this.forceUpdate()
    }
  }

  componentDidMount = () => {
    window.setTimeout(() => {
      this.props.scrollTarget().addEventListener('scroll', this.handleScroll)
      this.handleScroll()
    }, 0)
  }

  componentWillUnmount = () => {
    const elem = this.props.scrollTarget()
    if (elem) elem.removeEventListener('scroll', this.handleScroll)
  }

  render = () => {
    this.isStuck = this.shouldStick()

    let className = 'stick-to-top '
    let child = false
    if (this.isStuck) {
      className += 'stuck'
      child = this.props.alt
    } else {
      className += 'free'
    }

    return (
      <div className={className} ref="sticker">
        <div className="free-inner">
          {this.props.children}
        </div>
        <div className="stick-inner">
          <CSSTransitionGroup transitionName="stick-inner" component="div"
            transitionEnterTimeout={300} transitionLeaveTimeout={300}>
            {child}
          </CSSTransitionGroup>
        </div>
      </div>
    )
  }
}

export default StickToTop
