import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'

import './Job.sass'

export class Job extends React.Component {
  static propTypes = {
    pageName: PropTypes.string,
    params: PropTypes.object.isRequired
  }

  static defaultProps = {
    pageName: 'Job'
  }

  render = () => {
    const slug = this.props.params.slug
    const path = require('src/static/assets/images/pages/job/' + slug + '.png')
    const style = { backgroundImage: 'url("' + path + '")' }

    return (
      <div className={'page ' + slug} id={this.props.pageName}>
        <section id="intro">
          <div className="container">
            <div className="split">
              <div className="item left">
                <h1>
                  <FormattedMessage id={'job.intro.' + slug + '.title'} />
                </h1>
                <FormattedMessage id={'job.intro.' + slug + '.text'} />
              </div>
              <div className="item right" style={style} />
            </div>
          </div>
        </section>
        <section id="opportunity">
          <div className="container">
            <div className="features">
              <div className="feature location">
                <span className="icon icon-location" />
                <FormattedMessage id={'job.feature.' + slug + '.location'} />
              </div>
              <div className="feature time">
                <span className="icon icon-clock" />
                <FormattedMessage id={'job.feature.' + slug + '.time'} />
              </div>
            </div>
            <p>
              <FormattedMessage id={'job.opportunity.' + slug + '.text'} />
            </p>
          </div>
        </section>
        <a id="contact" href={'mailto:jobs@glue.digital?Subject=' + slug.replace(/-/g, '%20')}>
          <MarkdownMessage id="job.contact.text" />
          <strong>jobs@glue.digital</strong>
        </a>
        <section id="offer">
          <div className="container">
            <div id="responsibilities" className="card">
              <h3>
                <FormattedMessage id="job.responsibilities.title" />
              </h3>
              <ul>
                <FormattedMessage id={'job.responsibilities.' + slug}>
                  {txt => txt.split('\n').map((l, i) => <li key={i}>{l}</li>)}
                </FormattedMessage>
              </ul>
            </div>
            <div className="split">
              <div className="item left">
                <div id="we-offer" className="card">
                  <h3>
                    <FormattedMessage id="job.we-offer.title" />
                  </h3>
                  <ul>
                    <FormattedMessage id={'job.we-offer.' + slug}>
                      {txt => txt.split('\n').map((l, i) => <li key={i}>{l}</li>)}
                    </FormattedMessage>
                  </ul>
                </div>
              </div>
              <div className="item right">
                <div id="ratings" className="card">
                  <h3>
                    <FormattedMessage id="job.ratings.title" />
                  </h3>
                  <ul>
                    <FormattedMessage id={'job.ratings.' + slug}>
                      {txt => txt.split('\n').map((l, i) => <li key={i}>{l}</li>)}
                    </FormattedMessage>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="office">
          <div className="image image-1" />
          <div className="image image-2" />
          <div className="image image-3" />
        </section>
      </div>
    )
  }
}

export default Job
