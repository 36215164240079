export const unflattenObject = (obj) => {
  const ret = {}
  for (const key in obj) {
    const parts = key.split('.')
    let ptr = ret
    parts.forEach((part, i) => {
      if (i >= parts.length - 1) {
        // Leaf; assign it
        ptr[part] = obj[key]
      } else if (!isNaN(Number(parts[i + 1]))) {
        // Nested array followed by index
        ptr[part] = ptr[part] || []
      } else {
        // Nested object
        ptr[part] = ptr[part] || {}
      }
      ptr = ptr[part]
    })
  }
  return ret
}
