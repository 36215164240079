import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import { FormattedMessage } from 'react-intl'
import './Footer.sass'

// import ContactContainer from './containers/ContactContainer'

export class Footer extends React.Component {
  static propTypes = {
    pageName: PropTypes.string.isRequired
  }

  render = () => {
    const pageName = this.props.pageName
    return (
      <footer className={pageName}>
        {/*
          <ContactContainer />
        */}
        <div id="footer">
          <div className="container">
            <div className="social">
              <a
                target="_blank"
                href="https://twitter.com/Gluegl?lang=es"
                className="icon icon-twitter"
              />
              <a
                target="_blank"
                href="https://www.linkedin.com/company/glue-interactiva"
                className="icon icon-linkedin"
              />
            </div>
            <div className="split">
              <div className="item left">
                <Link to="/" className="glue">Glue.digital 2020</Link>
              </div>
              <div className="item right">
                <Link to="/workshops" className="workshops">
                  <FormattedMessage id="nav.workshops" />
                </Link>
                <Link to="/jobs" className="jobs">
                  <FormattedMessage id="nav.jobs" />
                </Link>
                <Link to="/stack" className="stack">
                  <FormattedMessage id="nav.stack" />
                </Link>
                <Link to="/legal" className="privacy">
                  <FormattedMessage id="nav.legal" />
                </Link>
                <Link to="/eu-funds" className="eu-funds">
                  <FormattedMessage id="nav.eu-funds" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
