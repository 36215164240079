export const CHAT_ADD_MESSAGE = 'CHAT_ADD_MESSAGE'
export const CHAT_CLEAR = 'CHAT_CLEAR'
export const CHAT_UPDATE_ADD_MESSAGE = 'CHAT_UPDATE_ADD_MESSAGE'

export function addMessage (type) {
  return {
    type: CHAT_ADD_MESSAGE,
    message: { type, timestamp: Date.now() }
  }
}

export function clear () {
  return {
    type: CHAT_CLEAR
  }
}
