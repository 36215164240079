import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'

import './Stack.sass'

export class Stack extends React.Component {
  static propTypes = {
    pageName: PropTypes.string
  }

  static defaultProps = {
    pageName: 'Stack'
  }

  render = () => {
    return (
      <div className="page" id={this.props.pageName}>
        <section id="intro">
          <div className="split">
            <div className="item left">
              <div className="half-container left">
                <h1>
                  <FormattedMessage id="stack.intro.title" />
                </h1>
                <FormattedMessage id="stack.intro.text" />
              </div>
            </div>
            <div className="item right" />
          </div>
        </section>
        <section id="tech">
          <div className="container">
            <div className="split">
              <div className="item left">
                <div className="card front">
                  <h2>
                    <FormattedMessage id="stack.tech.front.title" />
                  </h2>
                  <div className="text">
                    <p>
                      <MarkdownMessage id="stack.tech.front.text-1" />
                    </p>
                    <p>
                      <MarkdownMessage id="stack.tech.front.text-2" />
                    </p>
                    <p>
                      <MarkdownMessage id="stack.tech.front.text-3" />
                      <a target="_blank" href="https://github.com/GlueDigital">
                        <strong> https://github.com/GlueDigital</strong>
                      </a>
                    </p>
                  </div>
                  <div className="image" />
                </div>
              </div>
              <div className="item right">
                <div className="card back">
                  <h2>
                    <FormattedMessage id="stack.tech.back.title" />
                  </h2>
                  <div className="text">
                    <p>
                      <MarkdownMessage id="stack.tech.back.text-1" />
                    </p>
                    <p>
                      <MarkdownMessage id="stack.tech.back.text-2" />
                    </p>
                    <p>
                      <MarkdownMessage id="stack.tech.back.text-3" />
                    </p>
                    <p>
                      <MarkdownMessage id="stack.tech.back.text-4" />
                    </p>
                    <p>
                      <MarkdownMessage id="stack.tech.back.text-5" />
                    </p>
                    <p>
                      <MarkdownMessage id="stack.tech.back.text-6" />
                    </p>
                  </div>
                  <div className="image" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Stack
