import React from 'react'

export const legal = (props) => (
  <div className="container">
    <h1 className="page-title">
      Legal information
    </h1>
    <div className="intro-text">
      <p>
        This Website is owned by “Glue Digital Agency”, an entity located in
        López de Neira, number 3, Office 212; 36203 Vigo (Pontevedra), Spain.
      </p>
      <p>
        The use and access to the Website www.glue.digital (“Website”) by any
        person “User/Users”, as well as to its contents and services, are
        regulated by the present Terms and Conditions. By accessing this
        Website, Users acknowledge that they have read and agreed, without any
        type of reservation, to be bound by the Terms and Conditions in force at
        any time they access this Website. Glue Digital Agency reserves the
        right to carry out, at any time and with no prior notice, any changes or
        updates to the Terms and Conditions, contents and services and any
        elements generally integrated in the design and configuration of this
        Website.
      </p>
      <h2>
        Conditions of use
      </h2>
      <p>
        All material on this Website has been created by Glue Digital Agency for
        the sole purpose of informing. Under no circumstances may they be
        considered as actual legal advice. Likewise, accessing its contents does
        not imply the beginning of a professional relationship between the User
        and Glue Digital Agency. Therefore, the User must not act according to
        the information gathered in this Website disregarding legal advice.
      </p>
      <p>
        Glue Digital Agency will use all reasonable and available means in order
        to maintain the contents and services on this Website updated and
        accurate. Notwithstanding, the firm may not guarantee this circumstance
        at every moment. Thus, occasionally, the content might be incomplete or
        not completely accurate due to the constant modifications that usually
        affect law and case law.
      </p>
      <p>
        Accessing this Website is the Users' sole responsibility and they commit
        to use its contents according to the legislation in force and the
        present Terms and Conditions. Therefore, in the event of noncompliance,
        the User will be liable to Glue Digital Agency or to third parties for
        the damages that might be caused. According to the legislation currently
        in force, and for expository purposes only, we inform the Users that
        they may not:
      </p>
      <p>
        - Reproduce, copy, distribute, make available, publish, transform or
        change any contents, except in cases when it is legally allowed or
        specifically authorised by Glue Digital Agency or by whoever is entitled
        to exploitation rights, when relevant.
      </p>
      <p>
        - Reproduce or copy contents for private use when they may be considered
        Software or Database, according to the legislation in force regarding
        copyright matters, as well as publish or make them available to third
        parties whenever these acts necessarily imply the reproduction of the
        contents by the User or a third party.
      </p>
      <p>
        - Extract and/or reuse all or a substantial part of the contents
        included in this Website.
      </p>
      <h2>
        Liability
      </h2>
      <p>
        Users access the present Website at their own risk, so that Glue Digital
        Agency, its employees or collaborators shall not be liable for mistakes
        or omissions that there might be on this Website. Likewise, Glue Digital
        Agency shall not be held liable for the acts carried out by the User
        based on the information provided on the Website; neither for the
        damages that these acts might cause.
      </p>
      <p>
        This Website contains links (“linked sites”), which may lead the User to
        other websites managed by third parties, upon which Glue Digital Agency
        has no control over. Therefore, Glue Digital Agency shall not be liable
        for damages arisen from illegal, poor quality, out of date, unavailable,
          erroneous or unuseful contents on the sites linked to its Website;
        neither for other damages that are not directly attributable to the
        firm. Consequently, the firm, its employees and collaborators shall be
        exempt from any type of liability deriving from the use of the Website
        or other linked sites that may be directly accessed from Glue Digital
        Agency through these links.
      </p>
      <p>
        In the event of finding contents that may be considered as illegal or
        inappropriate on a linked site, the User may communicate it to Glue
        Digital Agency via any means of contact indicated on the Website.
        This communication shall under no circumstances imply the obligation of
        the linked site being removed by Glue Digital Agency.
      </p>
      <p>
        Glue Digital Agency shall not be liable for the functioning of this
        Website, since the firm advises that, like any other computer tool, it
        is not free of common functioning failures. In the same way, the firm
        shall not be liable for damages caused to Users as a result of failures
        or disconnections of telecommunication networks that may cause the
        suspension, cancellation or interruption of the Website service.
      </p>
      <p>
        Glue Digital Agency has adopted all the available measures, within the
        technological scope, to ensure the proper functioning of the Website.
        However, Glue Digital Agency is not obliged to guarantee the absence
        of viruses or other components with harmful effects when a User accesses
        the Website. Thus, it is the User's sole responsibility to have the
        appropriate tools for detecting them and disinfecting the computing
        system. Consequently, Glue Digital Agency shall not be liable for
        damages arising due to these elements on the User's computer (either on
        the hardware or the software).
      </p>
      <h2>
        Industrial and Intellectual Property Rights
      </h2>
      <p>
        All the industrial and intellectual property rights over the contents
        on this Website are held by Glue Digital Agency or third parties, which
        does not mean that any exploitation rights, beyond those which are
        strictly necessary for the correct use of the Website, are assigned to
        the User.
      </p>
      <p>
        Glue Digital Agency allows Users to visualise and obtain a temporary
        private copy of the contents on the Website for their own private use
        on their computers, as long as it is not used for commercial purposes.
        Users who want to use the contents on the Website for any other purpose
        and by any means, must previously request a written authorization to the
        corresponding rights' holder. Users wishing to reproduce information
        included on this Website, partially or totally, can send an email
        requesting authorisation to the following email address:
        info@glue.digital.
      </p>
      <p>
        Any person intending to create hyperlinks from their websites to Glue
        Digital Agency's Website, must previously obtain written authorisation.
        The establishment of a hyperlink under no circumstances implies the
        existence of a relationship between Glue Digital Agency and the owner
        of the corresponding website. In any case, Glue Digital Agency reserves
        the right to prohibit or disable any hyperlink at any time, especially
        in the case when the contents or the activity of the website which
        includes the hyperlink are illegal.
      </p>
      <p>
        Users will be liable to Glue Digital Agency or to third parties for any
        damages arising from improper use of the Website's contents.
      </p>
      <h2>
        Personal data protection
      </h2>
      <p>
        Navigating this Website does not imply the automatic registration of any
        personal details that might be used to identify the User.
      </p>
      <p>
        Users must not send to Glue Digital Agency any confidential information
        without previously consulting and obtaining an authorisation from the
        firm. Likewise, the firm advises that, despite making use of all the
        means available, it cannot guarantee the absolute confidentiality of the
        communications when this type of documentation is sent via e-mail, as
        the use of internet is involved.
      </p>
      <p>
        The information received by Glue Digital Agency shall be treated with
        absolute discretion. Forwarding personal data through the different
        means of contact to the firm constitutes the User's express and
        unequivocal acceptance for Glue Digital Agency to treat them for the
        sole purpose of managing its relationship with its clients or managing
        a selection process, in the event of forwarding CVs. Thus, the Users are
        informed, according to Article 5 of the Organic Law 15/1999 of 13
        December on the Protection of Personal Data and to the Royal Decree
        1720/2007, which approves the regulation for implementing it, that their
        data will be included in the firm's files, either automated or not,
        and will be used for the abovementioned purposes.
      </p>
      <p>
        The acceptance of the data treatment may be revoked at any moment,
        according to Articles 6 and 11 of the Organic Law 15/1999 of 13
        December, either in writing to Glue Digital Agency, in López de Neira,
        number 3, Office 212; 36203 Vigo (Pontevedra), Spain; or by means of
        the e.mail info@glue.digital. The rights to access, correction,
        cancellation and objection to personal data may also be exercised
        through the same channels, in accordance with the legislation currently
        in force.
      </p>
      <h2>
        Governing Law and Jurisdiction
      </h2>
      <p>
        The following conditions of access are subject to Spanish Law. The User
        and Glue Digital Agency agree to expressly bring any action arising
        from the access to this Website before the Spanish Courts and Tribunals
        located in the city of Vigo, and they revoke any other general or
        special jurisdiction that might correspond.
      </p>
      <h2>
        Contact addresses
      </h2>
      <p>
        In the event that any User has any questions about these Terms and
        Conditions or any suggestions about the Website, they may contact Glue
        Digital Agency through the contact details specified on
        www.glue.digital .
      </p>
    </div>
  </div>
)
export default legal
