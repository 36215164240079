import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import { CSSTransitionGroup } from 'react-transition-group'
import { ScrollContainer } from 'react-router-scroll'
import Header from '../../components/Header'
import HeaderHome from '../../components/HeaderHome'
import Footer from '../../components/Footer'
import { ModalWrapper, Modal } from '@gluedigital/modal'
import classes from './CoreLayout.sass'
import '../../styles/core.sass'

export class CoreLayout extends React.Component {

  static propTypes = {
    location: PropTypes.object.isRequired
  }

  shouldUpdateScroll = (pageName) => (prevRouterProps, { routes }) => {
    let otherPage = routes[1].component
    otherPage = otherPage.defaultProps ? otherPage.defaultProps.pageName : false
    return pageName === otherPage
  }

  render = () => {
    const location = this.props.location.pathname
    const pageName = React.Children.only(this.props.children).props.pageName
    let ifHeader = (<Header pageName={pageName} />)

    if (location === '/') {
      ifHeader = (<HeaderHome />)
    }

    const transitionOpts = {
      component: 'div',
      transitionName: 'pageFlip',
      transitionEnterTimeout: 200,
      transitionLeaveTimeout: 200
    }

    const shouldUpdateScroll = this.shouldUpdateScroll(pageName)

    const albacrossScript = `
      (function(a,l,b,c,r,s){_nQc=c,r=a.createElement(l),s=a.getElementsByTagName(l)[0];r.async=1;
      r.src=l.src=("https:"==a.location.protocol?"https://":"http://")+b;s.parentNode.insertBefore(r,s);
      })(document,"script","serve.albacross.com/track.js","89728675");
    `

    const intercomScript = `
      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');
      ic('update',intercomSettings);
      }else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};
      w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';
      s.async=true;s.src='https://widget.intercom.io/widget/ntre6bnq';
      var x=d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l)
      ;}else{w.addEventListener('load',l,false);}}})()
    `

    const analyticsScript = `
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

      ga('create', 'UA-84492521-1', 'auto');
      ga('send', 'pageview');
    `
    const keywords = 'Digital agency, Gluegl, Glue agency, Glue, Apps developer, web developer'
    const description =
      'Glue.digital. We help organizations ' +
      'and individuals to thrive in a connected world.'

    return (
      <ModalWrapper>
        <Helmet>
          <title>Glue. Digital Agency</title>
          <meta name="keywords" content={keywords} />
          <meta name="description" content={description} />
          <script>{albacrossScript}</script>
          <script>{intercomScript}</script>
          <script>{analyticsScript}</script>
        </Helmet>
        <CSSTransitionGroup {...transitionOpts}>
          <ScrollContainer key={pageName} scrollKey={pageName}
            shouldUpdateScroll={shouldUpdateScroll}>
            <div className="scrollableLayout">
              {ifHeader}
              <div className={classes.mainContainer}>
                {this.props.children}
              </div>
              <Footer pageName={pageName} />
            </div>
          </ScrollContainer>
        </CSSTransitionGroup>
        <Modal />
      </ModalWrapper>
    )
  }
}

CoreLayout.propTypes = {
  children: PropTypes.element.isRequired
}

export default CoreLayout
