import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import './EuFunds.sass'

export class EuFunds extends React.Component {
  static propTypes = {
    pageName: PropTypes.string
  }

  static contextTypes = {
    intl: PropTypes.object
  }

  static defaultProps = {
    pageName: 'EuFunds'
  }

  render = () => {

    return (
      <div className="page" id={this.props.pageName}>
        <section id="body">
          <section id="ifi">
            <h1>
              <FormattedMessage id="eufunds.ifi-title" />
            </h1>
            <a target="_blank" rel="noopener noreferrer" href="http://gain.xunta.gal/artigos/288/prestamos+ifi+innova">
              <div className="ifi-image" />
            </a>
          </section>
          <section id="profesionais">
            <h1>
              <FormattedMessage id="eufunds.profesionais-title" />
            </h1>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="profesionais-image"
              href="http://www.igape.es/es/ser-mais-competitivo/profesionais-4-0"
            />
          </section>
          <section id="feder">
            <h1>
              <FormattedMessage id="eufunds.reacciona-title" />
            </h1>
            <a target="_blank" rel="noopener noreferrer" href="http://reacciona.igape.es/index.php/re-acciona-2020/">
              <div className="feder-image" />
            </a>
          </section>
          <section id="principia">
            <h1>
              <FormattedMessage id="eufunds.principia-title" />
            </h1>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="principia-image"
              href="http://gain.xunta.gal/artigos/283/programa+principia"
            />
            <h3>
              <FormattedMessage id="eufunds.principia-text" />
            </h3>
          </section>
        </section>
      </div>
    )
  }
}

export default EuFunds
