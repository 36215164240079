import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import TimedMessage from '../TimedMessage'
import MessageInput from '../MessageInput'

export const Contact = (props) => {
  const ts = props.message.timestamp
  return (
    <div className="message message-contact">
      <div className="body">
        <TimedMessage startAt={ts} idx={0}>
          <FormattedMessage id="chat.contact.intro-1" />
        </TimedMessage>
        <TimedMessage startAt={ts} idx={1}>
          <FormattedMessage id="chat.contact.intro-3" />
        </TimedMessage>
      </div>

      <MessageInput {...props} to="contact2" back="cancel" startAt={ts} idx={3}
        prompt="chat.contact.name" cancel="chat.options.cancel" name="name" />
    </div>
  )
}

Contact.propTypes = {
  next: PropTypes.string,
  message: PropTypes.object,
  sendMessage: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired
}

export default Contact
