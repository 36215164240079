import PropTypes from 'prop-types'
import React from 'react'
// import { FormattedMessage } from 'react-intl'
import './NotFound.sass'

export class NotFound extends React.Component {
  static propTypes = {
    pageName: PropTypes.string
  }

  static defaultProps = {
    pageName: 'NotFound'
  }

  render = () => {
    return (
      <div className="page" id={this.props.pageName}>
        <section id="intro">
          <div className="container">
            <h1 className="page-title">
              404: Not found
            </h1>
          </div>
        </section>
      </div>
    )
  }
}

export default NotFound
