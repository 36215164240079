import React from 'react'
import { CSSTransitionGroup } from 'react-transition-group'
import { IndexLink } from 'react-router'
import { FormattedMessage } from 'react-intl'
import './Navigation.sass'

export class Navigation extends React.Component {

  handleMenu = () => {
    this.setState({ menu: this.state ? !this.state.menu : true, bg: false })
  }

  render = () => {
    let overlay = false
    let className = ''
    if (this.state && this.state.menu === true) {
      className = 'active'
      overlay = (
        <div className="overlay" key="overlay">
          <div className="wrapper">
            <div className="projects">
              <h3><FormattedMessage id="nav.projects" /></h3>
              <IndexLink className="creando" to="/creando_sabores">
                <FormattedMessage id="nav.creando" />
              </IndexLink>
              <IndexLink className="hearthy" to="/hearthy">
                <FormattedMessage id="nav.hearthy" />
              </IndexLink>
              <IndexLink className="fundivisa" to="/fundivisa">
                <FormattedMessage id="nav.fundivisa" />
              </IndexLink>
              <IndexLink className="unleash" to="/unleash">
                <FormattedMessage id="nav.unleash" />
              </IndexLink>
              <IndexLink className="kfc" to="/kfc">
                <FormattedMessage id="nav.kfc" />
              </IndexLink>
              <IndexLink className="ebaySocial" to="/ebay_social">
                <FormattedMessage id="nav.ebaySocial" />
              </IndexLink>
              <IndexLink className="listaRobinson" to="/lista_robinson">
                <FormattedMessage id="nav.listaRobinson" />
              </IndexLink>
              <IndexLink className="ministerioConsumo" to="/ministerio_consumo">
                <FormattedMessage id="nav.ministerioConsumo" />
              </IndexLink>
            </div>
            <div className="pages">
              <IndexLink className="jobs" to="/jobs">
                <FormattedMessage id="nav.jobs" />
              </IndexLink>
              <IndexLink className="stack" to="/stack">
                <FormattedMessage id="nav.stack" />
              </IndexLink>
            </div>
          </div>
        </div>
      )
    }

    return (
      <nav id="home-navigation" className={className}>
        <div className="container">
          <div className="left" />
          <a href="#header-chat" className="centre">
            <div className="text">
              <div className="bubble" />
              <FormattedMessage id="nav.talk" />
            </div>
          </a>
          <div className="right">
            <div id="hamburguer" onClick={this.handleMenu}>
              <div className="stripe top" />
              <div className="stripe middle" />
              <div className="stripe bottom" />
            </div>
            <CSSTransitionGroup
              transitionName="overlay" component="div"
              transitionEnterTimeout={300} transitionLeaveTimeout={300}
            >
              {overlay}
            </CSSTransitionGroup>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navigation
