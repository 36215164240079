import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Projects from '../../components/Projects'
import ShipLink from '../../components/ShipLink'
import parallastic from '../../utils/parallastic'
import './Medipremium.sass'

export class Medipremium extends React.Component {
  static propTypes = {
    pageName: PropTypes.string
  }

  static defaultProps = {
    pageName: 'Medipremium'
  }

  componentDidMount = () => {
    const p = parallastic(this.scrollTarget())
    p.addToggler(this.refs.laptop, 'into-view', 0.40)
    p.addToggler(this.refs.animation, 'into-view', 0.40)
    p.addToggler(this.refs.chat, 'into-view', 0.40)
    p.addToggler(this.refs.webapp, 'into-view', 0.40)
    p.addToggler(this.refs.belive, 'into-view', 0.40)
    p.addToggler(this.refs.plan, 'into-view', 0.40)
    p.addToggler(this.refs.analytics, 'into-view', 0.40)

    p.addToggler(this.refs.section, 'into-view', 0.25)
    p.addToggler(this.refs.section2, 'into-view', 0.25)
  }

  scrollTarget = () =>
    this.refs.medipremium ? this.refs.medipremium.parentNode.parentNode : false

  render = () => {
    return (
      <div className="page" id={this.props.pageName} ref="medipremium">
        <section id="intro">
          <div className="container">
            <h1 className="page-title">
              {this.props.pageName}
            </h1>
            <div className="intro-text">
              <p>
                <FormattedMessage id="medi.intro-text" />
              </p>
            </div>
          </div>
        </section>
        <section className="features" id="feature-1">
          <div className="left">
            <h2 className="title">
              <FormattedMessage id="medi.feature-1-title" />
            </h2>
            <p>
              <FormattedMessage id="medi.feature-1-text" />
            </p>
          </div>
          <div className="right" ref="laptop" />
        </section>
        <section className="features" id="feature-2">
          <div className="left">
            <h2 className="title">
              <FormattedMessage id="medi.feature-2-title" />
            </h2>
            <p>
              <FormattedMessage id="medi.feature-2-text" />
            </p>
          </div>
          <div className="right">
            <div className="animation" ref="animation" />
          </div>
        </section>
        <section className="features" id="feature-3" ref="section">
          <div className="container">
            <div className="left">
              <div className="feature-3-icon" ref="chat" />
              <h3 className="feature-3-title-1">
                <FormattedMessage id="medi.feature-3-title-1" />
              </h3>
              <p>
                <FormattedMessage id="medi.feature-3-text-1" />
              </p>
            </div>
            <div className="right">
              <div className="feature-3-icon" ref="webapp" />
              <h3 className="feature-3-title-2">
                <FormattedMessage id="medi.feature-3-title-2" />
              </h3>
              <p>
                <FormattedMessage id="medi.feature-3-text-2" />
              </p>
            </div>
          </div>
        </section>
        <section id="belive" ref="belive">
          <div className="veil" />
          <div className="container">
            <div className="hand" />
            <h2 className="belive-text">
              <FormattedMessage id="medi.belive-text" />
            </h2>
          </div>
        </section>
        <section className="features" id="feature-4" ref="section2">
          <div className="container">
            <div className="left">
              <div className="feature-4-icon" ref="plan" />
              <h3 className="feature-4-title-1">
                <FormattedMessage id="medi.feature-4-title-1" />
              </h3>
              <p>
                <FormattedMessage id="medi.feature-4-text-1" />
              </p>
            </div>
            <div className="right">
              <div className="feature-4-icon" ref="analytics" />
              <h3 className="feature-4-title-2">
                <FormattedMessage id="medi.feature-4-title-2" />
              </h3>
              <p>
                <FormattedMessage id="medi.feature-4-text-2" />
              </p>
            </div>
          </div>
        </section>
        <ShipLink
          pageName="medipremium"
          link="http://medipremium.com/"
        />
        <Projects prev="kfc" next="creando_sabores" />
      </div>
    )
  }
}

export default Medipremium
