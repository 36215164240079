import Hello from './Hello'
import Works from './Works'
import Glue from './Glue'
import Astro from './Astro'
import Contact from './Contact'
import Contact2 from './Contact2'
import Cancel from './Cancel'
import Thanks from './Thanks'

export default {
  hello: Hello,
  works: Works,
  glue: Glue,
  astro: Astro,
  contact: Contact,
  contact2: Contact2,
  cancel: Cancel,
  thanks: Thanks
}
