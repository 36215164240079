import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import ShipLink from '../../components/ShipLink'
import Projects from '../../components/Projects'
import parallastic from '../../utils/parallastic'
import './Hearthy.sass'

export class Hearthy extends React.Component {
  static propTypes = {
    pageName: PropTypes.string
  }

  static defaultProps = {
    pageName: 'Hearthy'
  }

  componentDidMount = () => {
    const p = parallastic(this.scrollTarget())
    p.addToggler(this.refs.mobile, 'into-view', 0.40)
    p.addToggler(this.refs.medication, 'into-view', 0.40)
    p.addToggler(this.refs.payments, 'into-view', 0.40)

    p.addToggler(this.refs.section, 'into-view', 0.25)
  }

  scrollTarget = () =>
    this.refs.hearthy ? this.refs.hearthy.parentNode.parentNode : false

  render = () => {
    return (
      <div className="page" id={this.props.pageName} ref="hearthy">
        <section id="intro">
          <div className="container">
            <h1 className="page-title">
              {this.props.pageName} App
            </h1>
            <div className="intro-text">
              <p>
                <FormattedMessage id="hearthy.intro-text-1" />
              </p>
              <p>
                <FormattedMessage id="hearthy.intro-text-2" />
              </p>
            </div>
          </div>
        </section>
        <section className="features" id="feature-1">
          <div className="left">
            <h2 className="title">
              <FormattedMessage id="hearthy.feature-1-title" />
            </h2>
            <p>
              <FormattedMessage id="hearthy.feature-1-text" />
            </p>
          </div>
          <div className="right" ref="mobile" />
        </section>
        <section className="features" id="feature-2">
          <div className="container">
            <h2 className="title">
              <FormattedMessage id="hearthy.feature-2-title" />
            </h2>
            <p>
              <FormattedMessage id="hearthy.feature-2-text" />
            </p>
          </div>
        </section>
        <section className="features" id="feature-3" ref="section">
          <div className="container">
            <div className="left">
              <div className="feature-3-icon" ref="medication" />
              <h3 className="feature-3-title-1">
                <FormattedMessage id="hearthy.feature-3-title-1" />
              </h3>
              <p>
                <FormattedMessage id="hearthy.feature-3-text-1" />
              </p>
            </div>
            <div className="right">
              <div className="feature-3-icon" ref="payments" />
              <h3 className="feature-3-title-2">
                <FormattedMessage id="hearthy.feature-3-title-2" />
              </h3>
              <p>
                <FormattedMessage id="hearthy.feature-3-text-2" />
              </p>
            </div>
          </div>
        </section>
        <section className="features" id="feature-4" />
        <section className="features" id="feature-5">
          <div className="container">
            <h2 className="title">
              <FormattedMessage id="hearthy.feature-5-title" />
            </h2>
            <p>
              <FormattedMessage id="hearthy.feature-5-text" />
            </p>
          </div>
        </section>
        <section className="features" id="feature-6">
          <div className="container">
            <h2 className="title">
              <FormattedMessage id="hearthy.feature-6-title" />
            </h2>
            <p>
              <FormattedMessage id="hearthy.feature-6-text" />
            </p>
          </div>
        </section>
        <ShipLink
          pageName="hearthy"
          link="http://hearthy.co/"
        />
        <Projects prev="creando_sabores" next="fundivisa" />
      </div>
    )
  }
}

export default Hearthy
