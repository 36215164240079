import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import TimedMessage from '../TimedMessage'
import { MessageOptions, Option } from '../MessageOptions'

export const Thanks = (props) => {
  const ts = props.message.timestamp
  return (
    <div className="message message-thanks">
      <div className="body">
        <TimedMessage startAt={ts} idx={0}>
          <FormattedMessage id="chat.thanks.intro" />
        </TimedMessage>
      </div>

      <MessageOptions prompt="chat.options.something-else" startAt={ts} idx={1}
        next={props.next} sendMessage={props.sendMessage}>
        <Option to="works" text="chat.options.show-work" />
        <Option to="glue" text="chat.options.about-glue" />
        <Option to="astro" text="chat.options.about-you" />
      </MessageOptions>
    </div>
  )
}

Thanks.propTypes = {
  next: PropTypes.string,
  message: PropTypes.object,
  sendMessage: PropTypes.func.isRequired
}

export default Thanks
