import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import TimedMessage from './TimedMessage'
import './MessageOptions.sass'

export const MessageOptions = (props) => {
  let decorate = (c, i, extras = {}) => {
    const clone = React.cloneElement(c, {
      ...extras,
      sendMessage: props.sendMessage,
      disabled: !!props.next
    })
    return (
      <TimedMessage startAt={props.startAt} idx={props.idx + 1 + 0.3 * i}>
        {clone}
      </TimedMessage>
    )
  }
  let options
  if (props.next) {
    options = React.Children.map(props.children, (c, i) => {
      const opts = {}
      if (c.props.to !== props.next) opts['className'] = 'rejected'
      return decorate(c, i, opts)
    })
  } else {
    options = React.Children.map(props.children, decorate)
  }

  return (
    <div className="options">
      <div className="prompt">
        <TimedMessage startAt={props.startAt} idx={props.idx}>
          <FormattedMessage id={props.prompt} />
        </TimedMessage>
      </div>
      <ul>
        {options}
      </ul>
    </div>
  )
}

MessageOptions.propTypes = {
  'children': PropTypes.array.isRequired,
  'next': PropTypes.string,
  'prompt': PropTypes.string.isRequired,
  'sendMessage': PropTypes.func.isRequired,
  'startAt': PropTypes.number,
  'idx': PropTypes.number
}

export const Option = (props) => {
  let handler = function () {
    props.sendMessage(props.to)
  }
  let className = 'option'
  if (props.className) className += ' ' + props.className
  if (props.disabled) {
    handler = null
    className += ' disabled'
  }
  return (
    <li onClick={handler} className={className}>
      <FormattedMessage id={props.text} />
    </li>
  )
}

Option.propTypes = {
  'className': PropTypes.string,
  'disabled': PropTypes.bool,
  'sendMessage': PropTypes.func,
  'text': PropTypes.string.isRequired,
  'to': PropTypes.string.isRequired
}

export default MessageOptions
