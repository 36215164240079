import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import ShipLink from '../../components/ShipLink'
import Projects from '../../components/Projects'
import parallastic from '../../utils/parallastic'
import './ListaRobinson.sass'

export class ListaRobinson extends React.Component {
  static propTypes = {
    pageName: PropTypes.string
  }

  static defaultProps = {
    pageName: 'ListaRobinson'
  }

  componentDidMount = () => {
    const p = parallastic(this.scrollTarget())
    p.addToggler(this.refs.padlock, 'into-view', 0.40)
    p.addToggler(this.refs.users, 'into-view', 0.40)
    p.addToggler(this.refs.architecture, 'into-view', 0.40)

    p.addToggler(this.refs.section, 'into-view', 0.25)
  }

  scrollTarget = () =>
    this.refs.listaRobinson ? this.refs.listaRobinson.parentNode.parentNode : false

  render = () => {
    return (
      <div className="page" id={this.props.pageName} ref="listaRobinson">
        <section id="intro">
          <div className="container">
            <h1 className="page-title">
              <FormattedMessage id="listaRobinson.page-title" />
            </h1>
            <div className="intro-text">
              <p>
                <FormattedMessage id="listaRobinson.intro-text-1" />
              </p>
            </div>
          </div>
        </section>

        <section className="features" id="feature-1">
          <div className="left">
            <p>
              <MarkdownMessage id="listaRobinson.feature-1-text-1" />
            </p>
            <p>
              <FormattedMessage id="listaRobinson.feature-1-text-2" />
            </p>
          </div>
          <div className="video-wrapper right" ref="padlock">
            <video autoPlay muted playsInline className="video">
              <source src="/assets/video/listarobinson.webm" type="video/webm" codecs="vp9" />
              <source src="/assets/video/listarobinson.mp4" type="video/mp4" />
            </video>
          </div>
        </section>

        <section className="features" id="feature-2" ref="section">
          <div className="container">
            <div className="left">
              <div className="feature-2-icon" ref="architecture" />
              <p>
                <FormattedMessage id="listaRobinson.feature-2-text-1" />
              </p>
            </div>
            <div className="right">
              <div className="feature-2-icon" ref="users" />
              <p>
                <FormattedMessage id="listaRobinson.feature-2-text-2" />
              </p>
            </div>
          </div>
        </section>

        <section className="features" id="comment">
          <div className="container">
            <p>
              <FormattedMessage id="listaRobinson.feature-3-text-1" />
            </p>
          </div>
        </section>

        <ShipLink
          pageName="listaRobinson"
          link="https://www.listarobinson.es/"
        />
        <Projects prev="kfc" next="creando_sabores" />
      </div>
    )
  }
}

export default ListaRobinson
