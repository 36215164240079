import PropTypes from 'prop-types'
import React from 'react'
// import { FormattedMessage } from 'react-intl'
import './Legal.sass'

// Locally decide lang
import LegalEn from './components/legal-en'
import LegalEs from './components/legal-es'

export class Legal extends React.Component {
  static propTypes = {
    pageName: PropTypes.string
  }

  static contextTypes = {
    intl: PropTypes.object
  }

  static defaultProps = {
    pageName: 'Legal'
  }

  render = () => {
    const lang = this.context.intl.locale
    const content = lang === 'es' ? (<LegalEs />) : (<LegalEn />)
    return (
      <div className="page" id={this.props.pageName}>
        <section id="body">
          {content}
        </section>
      </div>
    )
  }
}

export default Legal
