import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import { FormattedMessage } from 'react-intl'
import parallastic from '../../utils/parallastic'
import './Projects.sass'

export class Projects extends React.Component {

  static propTypes = {
    prev: PropTypes.string.isRequired,
    next: PropTypes.string.isRequired
  }

  componentDidMount = () => {
    const p = parallastic(this.scrollTarget())
    p.addToggler(this.refs.projects, 'into-view', 0.30)
  }

  scrollTarget = () =>
    this.refs.projects ? this.refs.projects.parentNode.parentNode.parentNode : false

  render = () => {
    const bgUrl = "url('/assets/images/projects/"
    const bgPrev = {
      backgroundImage: bgUrl + this.props.prev + ".png')"
    }
    const bgNext = {
      backgroundImage: bgUrl + this.props.next + ".png')"
    }

    const classLeft = 'item left ' + this.props.prev
    const classRight = 'item right ' + this.props.next

    return (
      <section id="next-projects" ref="projects">
        <div className="container">
          <div className={classLeft}>
            <Link to={this.props.prev} className="content" style={bgPrev}>
              <h3 className="title">
                <span className="icon icon-back" />
                <FormattedMessage id="projects.prev" />
                <strong>
                  {this.props.prev.replace('_', ' ')}
                </strong>
              </h3>
            </Link>
          </div>
          <div className={classRight}>
            <Link to={this.props.next} className="content" style={bgNext}>
              <h3 className="title">
                <FormattedMessage id="projects.next" />
                <strong>
                  {this.props.next.replace('_', ' ')}
                </strong>
                <span className="icon icon-next" />
              </h3>
            </Link>
          </div>
        </div>
      </section>
    )
  }
}

export default Projects
