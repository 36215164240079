import PropTypes from 'prop-types'
import React from 'react'
import MessageInput from '../MessageInput'

export const Contact2 = (props) => {
  const ts = props.message.timestamp
  return (
    <div className="message message-contact2">
      <MessageInput {...props} to="thanks" back="cancel" startAt={ts} idx={0}
        prompt="chat.contact.email" cancel="chat.options.cancel" name="email" />
    </div>
  )
}

Contact2.propTypes = {
  next: PropTypes.string,
  message: PropTypes.object,
  sendMessage: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired
}

export default Contact2
