export const INFO_SAVE = 'INFO_SAVE'

export function save (key, value) {
  return (dispatch, getState) => {
    dispatch({
      type: INFO_SAVE,
      key: key,
      value: value
    })
    if (key === 'email') { // Change trigger here if needed
      const info = getState().info
      const body = new FormData()
      body.append('Email', info.email)
      body.append('Last Name', info.name || 'Unknown')
      const request = new Request('//api.glue.digital/', {
        method: 'POST',
        mode: 'no-cors',
        body: body
      })
      fetch(request)
        .then(() => { console.log('Success!') })
        .catch((e) => { console.log('Error!', e) })
    }
  }
}
