import React from 'react'
import { IndexRoute, Route } from 'react-router'
import { useScroll } from 'react-router-scroll'

import CoreLayout from '../layouts/CoreLayout/CoreLayout'
import Home from './Home'
import Medipremium from './Medipremium'
import Enqsta from './Enqsta'
import Fundivisa from './Fundivisa'
import Creando from './Creando'
import Hearthy from './Hearthy'
import Unleash from './Unleash'
import EbaySocial from './EbaySocial'
import ListaRobinson from './ListaRobinson'
import MinisterioConsumo from './MinisterioConsumo'
import Kfc from './Kfc'
import Legal from './Legal'
import Job from './Job'
import Jobs from './Jobs'
import Workshops from './Workshops'
import Stack from './Stack'
import EuFunds from './EuFunds'
import NotFound from './NotFound'

const routes = () => {
  return (
    <Route component={CoreLayout} path="/">
      <IndexRoute component={Home} />
      <Route path="creando_sabores" component={Creando} />
      <Route path="hearthy" component={Hearthy} />
      <Route path="medipremium" component={Medipremium} />
      <Route path="fundivisa" component={Fundivisa} />
      <Route path="enqsta" component={Enqsta} />
      <Route path="unleash" component={Unleash} />
      <Route path="kfc" component={Kfc} />
      <Route path="ebay_social" component={EbaySocial} />
      <Route path="lista_robinson" component={ListaRobinson} />
      <Route path="ministerio_consumo" component={MinisterioConsumo} />
      <Route path="jobs" component={Jobs} />
      <Route path="jobs/:slug" component={Job} />
      <Route path="stack" component={Stack} />
      <Route path="workshops" component={Workshops} />
      <Route path="eu-funds" component={EuFunds} />
      <Route path="legal" component={Legal} />
      <Route path="*" component={NotFound} />
    </Route>
  )
}

export const routerMiddlewares = [useScroll()]

export default routes
