import PropTypes from 'prop-types'
import React from 'react'
import Messages from './messages'
import './MessageList.sass'

export class MessageList extends React.Component {
  static propTypes = {
    'messages': PropTypes.array.isRequired,
    'info': PropTypes.object.isRequired,
    'sendMessage': PropTypes.func.isRequired,
    'saveInfo': PropTypes.func.isRequired
  }

  componentDidMount = () => {
    if (!this.props.messages.length) {
      setTimeout(() => this.props.sendMessage('hello'), 10500)
    }
  }

  render = () => {
    const messages = this.props.messages.map((message, i) => {
      const messageElement = Messages[message.type]
      if (!messageElement) {
        console.warn('Unknown message type:', message.type)
        return false
      }
      const nextType = this.props.messages[i + 1] ? this.props.messages[i + 1].type : null
      return React.createElement(messageElement, {
        key: i,
        index: i,
        next: nextType,
        sendMessage: this.props.sendMessage,
        saveInfo: this.props.saveInfo,
        message: message,
        info: this.props.info
      })
    })

    return (
      <div className="message-list">
        {messages}
      </div>
    )
  }
}

export default MessageList
