import React from 'react'
import PropTypes from 'prop-types'
import { registerModal } from '@gluedigital/modal'
import { FormattedMessage } from 'react-intl'
import { MultistepForm, FormStep, FormState, NextButton } from '@gluedigital/multistep-form'
import MarkdownMessage from '@gluedigital/markdown-message'
import { Link } from 'react-router'
import { unflattenObject } from 'src/utils/unflattenObject'

import './WorkshopForm.sass'

class WorkshopForm extends React.Component {

  static propTypes = {
    intl: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)
    this.state = { done: false }
  }

  handleSubmit = (data) => {
    const body = unflattenObject(data)
    body.fields = Object.keys(body.fields)
      .map((key) => ({ name: key, value: body.fields[key] }))
    const action =
      'https://api.hsforms.com/submissions/v3/integration/submit/5503435/' +
      'bdcd739c-abd5-4d7a-ac9b-754313663409'
    fetch(action, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          done: true,
          error: res.status === 'error'
        })
      })
  }

  // Success message
  renderDone = () => {
    if (this.state.error) {
      return (
        <div className="status error">
          <div className="wrapp">
            <div className="image" />
            <h1><MarkdownMessage id="workshop-form.error" /></h1>
          </div>
        </div>
      )
    } else {
      return (
        <div className="status success">
          <div className="wrapp">
            <div className="image" />
            <h1><MarkdownMessage id="workshop-form.success" /></h1>
          </div>
        </div>
      )
    }
  }

  renderForm = () => {
    const buttonSubmit = (
      <FormState>
        {({ step, data }) => {
          if (step !== 6) {
            return (
              <button type="submit">
                <FormattedMessage id="next" />
                <span className="icon icon-next" />
              </button>
            )
          } else {
            return (
              <button type="submit">
                <FormattedMessage id="workshop-form.submit" />
                <span className="icon icon-next" />
              </button>
            )
          }
        }}
      </FormState>
    )
    const buttonBack = (
      <FormState>
        {({ step, data }) => {
          if (step !== 1) {
            return (
              <NextButton className="back" noValidate next={step - 1}>
                <span className="icon icon-back" />
                <FormattedMessage id="back" />
              </NextButton>
            )
          } else {
            return (
              <button className="disabled">
                <span className="icon icon-back" />
                <FormattedMessage id="back" />
              </button>
            )
          }
        }}
      </FormState>
    )
    return (
      <MultistepForm steps={6} onSubmit={this.handleSubmit}>
        <div className="form-image" />
        <h1>
          <FormattedMessage id="workshop-form.title" />
        </h1>
        <div className="steps">
          <strong>
            <FormState>{({ step }) => `${step}`}</FormState>
          </strong>
          <span>
            /6
          </span>
        </div>
        <div className="wrapper">
          <FormStep className="step" step={1}>
            <h3>
              <MarkdownMessage id="workshop-form.intro" />
            </h3>
            <label>
              <FormattedMessage id="workshop-form.email" >
                {(text) =>
                  <input
                    type="email"
                    name="fields.email"
                    required autoFocus
                    placeholder={text}
                  />
                }
              </FormattedMessage>
            </label>
          </FormStep>
          <FormStep className="step" step={2}>
            <h3>
              <MarkdownMessage id="workshop-form.intro" />
            </h3>
            <label>
              <FormattedMessage id="workshop-form.firstname" >
                {(text) =>
                  <input
                    type="text"
                    name="fields.firstname"
                    required autoFocus
                    placeholder={text}
                  />
                }
              </FormattedMessage>
            </label>
          </FormStep>
          <FormStep className="step" step={3}>
            <h3>
              <MarkdownMessage id="workshop-form.intro" />
            </h3>
            <label>
              <FormattedMessage id="workshop-form.lastname" >
                {(text) =>
                  <input
                    type="text"
                    name="fields.lastname"
                    required autoFocus
                    placeholder={text}
                  />
                }
              </FormattedMessage>
            </label>
          </FormStep>
          <FormStep className="step" step={4}>
            <h3>
              <MarkdownMessage id="workshop-form.intro" />
            </h3>
            <label>
              <FormattedMessage id="workshop-form.phone" >
                {(text) =>
                  <input
                    type="tel"
                    name="fields.phone"
                    required autoFocus
                    placeholder={text}
                    pattern="\+?[0-9]{6,}"
                  />
                }
              </FormattedMessage>
            </label>
          </FormStep>
          <FormStep className="step" step={5}>
            <h3>
              <MarkdownMessage id="workshop-form.intro" />
            </h3>
            <label>
              <FormattedMessage id="workshop-form.company" >
                {(text) =>
                  <input
                    type="text"
                    name="fields.company"
                    required autoFocus
                    placeholder={text}
                  />
                }
              </FormattedMessage>
            </label>
          </FormStep>
          <FormStep className="step" step={6}>
            <div className="consent">
              <label>
                <input required type="checkbox" />
                <span />
              </label>
              <div className="text">
                <Link target="_blank" to="/legal"><MarkdownMessage id="workshop-form.contact.legal.link" /></Link>
                <FormattedMessage id="workshop-form.contact.legal" />
              </div>
            </div>
          </FormStep>
        </div>
        <div className="buttons">
          {buttonSubmit}
          {buttonBack}
        </div>
      </MultistepForm>
    )
  }

  render = () => {
    return (
      <div id="workshop-form">
        <div className="split">
          <div className="item left">
            <h1>
              <MarkdownMessage id="workshops.intro.title" />
            </h1>
            <p>
              {/* <MarkdownMessage id="workshops.intro.text" /> */}
            </p>
          </div>
          <div className="item right">
            { this.state.done ? this.renderDone() : this.renderForm() }
          </div>
        </div>
      </div>
    )
  }
}

registerModal('workshop-modal', WorkshopForm)
