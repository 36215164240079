import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import { ModalLink } from '@gluedigital/modal'

import './WorkshopForm'
import './Workshops.sass'

const Workshops = () => {

  return (
    <div className="page" id="Workshops">
      <header id="workshops-header">
        <div className="container">
          <ModalLink tagName="button" modal="workshop-modal">
            <span className="laptop">
              <MarkdownMessage id="workshops.modal-link" />
            </span>
            <span className="mobile">
              <MarkdownMessage id="workshops.modal-link-mobile" />
            </span>
          </ModalLink>
        </div>
      </header>
      <section id="intro">
        <div className="lab" />
        <div className="container">
          <div className="split">
            <div className="item left">
              <h1>
                <MarkdownMessage id="workshops.intro.title" />
              </h1>
              {/* <MarkdownMessage id="workshops.intro.text" /> */}
            </div>
            <div className="item right" />
          </div>
        </div>
      </section>
      <section id="section-0">
        <div className="container">
          <h1>
            <MarkdownMessage id="workshops.section-0.title" />
          </h1>
          <div className="split">
            <div className="item left">
              <p>
                <MarkdownMessage id="workshops.section-0.text-1" />
              </p>
            </div>
            <div className="item right">
              <p>
                <MarkdownMessage id="workshops.section-0.text-2" />
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="section-1">
        <div className="container">
          <div className="split">
            <div className="item left">
              <div className="card">
                <h2>
                  <FormattedMessage id="workshops.section-1.left.title" />
                </h2>
                <div className="data">
                  <div className="session">
                    <span className="icon icon-clock" />
                    <FormattedMessage id="workshops.section-1.session" />
                  </div>
                  <div className="cost">
                    <span className="icon icon-money" />
                    <FormattedMessage id="workshops.section-1.cost" />
                  </div>
                </div>
                <div className="text">
                  <FormattedMessage id="workshops.section-1.left.text">
                    {txt => txt.split('\n').map((l, j) => <p key={j}>{l}</p>)}
                  </FormattedMessage>
                </div>
              </div>
            </div>
            <div className="item right">
              <div className="card">
                <h2>
                  <FormattedMessage id="workshops.section-1.right.title" />
                </h2>
                <div className="data">
                  <div className="community">
                    <span className="icon icon-community" />
                    <FormattedMessage id="workshops.section-1.community" />
                  </div>
                </div>
                <div className="text">
                  <FormattedMessage id="workshops.section-1.right.text">
                    {txt => txt.split('\n').map((l, j) => <p key={j}>{l}</p>)}
                  </FormattedMessage>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="banner" />
      <section id="section-2">
        <div className="container">
          <div className="split">
            <div className="item left">
              <div className="card">
                <h2>
                  <FormattedMessage id="workshops.section-2.left.title" />
                </h2>
                <p>
                  <MarkdownMessage id="workshops.section-2.left.text" />
                </p>
              </div>
            </div>
            <div className="item right">
              <div className="card">
                <h2>
                  <FormattedMessage id="workshops.section-2.right.title" />
                </h2>
                <p>
                  <MarkdownMessage id="workshops.section-2.right.text" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section-3">
        <div className="container">
          <div className="card">
            <h2>
              <FormattedMessage id="workshops.section-3.title" />
            </h2>
            <div className="split">
              <div className="item left">
                <p>
                  <MarkdownMessage id="workshops.section-3.left.text" />
                </p>
              </div>
              <div className="item right">
                <p>
                  <MarkdownMessage id="workshops.section-3.right.text" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="workshop-modal">
        <h3><FormattedMessage id="workshops.workshop-modal.title" /></h3>
        <ModalLink tagName="button" modal="workshop-modal">
          <MarkdownMessage id="workshops.modal-link" />
        </ModalLink>
      </section>
    </div>
  )
}

Workshops.propTypes = {
  pageName: PropTypes.string
}
Workshops.defaultProps = {
  pageName: 'Workshops'
}
export default Workshops
