import React from 'react'
import { Link } from 'react-router'
import { FormattedMessage } from 'react-intl'
import VisibleMessageList from './containers/VisibleMessageList'
import './HeaderHome.sass'

let state = 'loading'

export class HeaderHome extends React.Component {
  componentWillMount = () => {
    this.setState({ state: state })
  }

  componentDidMount = () => {
    if (state === 'loading') {
      if (document.readyState === 'complete') {
        this.setGlobalState('loaded')
      } else {
        window.addEventListener('load', this.setGlobalState.bind(this, 'loaded'))
      }
    }
  }

  setGlobalState = (newState) => {
    state = newState
    this.setState({ state: state })
  }

  componentWillUnmount = () => {
    state = 'finished' // Set it for the next time
  }

  render = () => {
    return (
      <header id="header-chat" className={this.state.state}>
        <div className="stars" />
        <div className="saturn4" />
        <div className="saturn" />
        <div className="saturn3" />
        <div className="saturn2" />
        <div className="planet-bg" />
        <div className="planet" />
        <div className="planet-fg" />
        <div className="astro-wrapper">
          <div className="backpack" />
          <div className="astro" >
            <div className="flares" />
          </div>
        </div>
        <div className="veil">
          <VisibleMessageList />
        </div>
        <div className="container">
          <Link className="icon icon-glue" to="/" />
        </div>
        <div id="skip">
          <a href="#intro" data-offset="70">
            <FormattedMessage id="home.skip" />
            <div className="icon icon-down" />
          </a>
        </div>
      </header>
    )
  }
}

export default HeaderHome
