import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import { FormattedMessage } from 'react-intl'
import TimedMessage from '../TimedMessage'
import { MessageOptions, Option } from '../MessageOptions'

export const Works = (props) => {
  const ts = props.message.timestamp
  const bgImg = (project) => ({
    backgroundImage: "url('/assets/images/projects/" + project + ".png')"
  })

  return (
    <div className="message message-works">
      <div className="body">
        <TimedMessage startAt={ts} idx={0}>
          <FormattedMessage id="chat.works.intro" />
        </TimedMessage>
        <TimedMessage startAt={ts} idx={1}>
          <div className="works">
            <Link to="/creando_sabores" className="work creando">
              <div style={bgImg('creando_sabores')} />
              <div className="name">
                <FormattedMessage id="nav.creando" />
              </div>
            </Link>
            <Link to="/enqsta" className="work enqsta">
              <div style={bgImg('enqsta')} />
              <div className="name">
                <FormattedMessage id="nav.enqsta" />
              </div>
            </Link>
            <Link to="/medipremium" className="work medipremium">
              <div style={bgImg('medipremium')} />
              <div className="name">
                <FormattedMessage id="nav.medipremium" />
              </div>
            </Link>
            <a href="#projects" className="work">
              <div className="more">
                <FormattedMessage id="chat.works.more" />
              </div>
            </a>
          </div>
        </TimedMessage>
        <TimedMessage startAt={ts} idx={2}>
          <FormattedMessage id="chat.works.liked" />
        </TimedMessage>
      </div>

      <MessageOptions prompt="chat.options.something-else" startAt={ts} idx={3}
        next={props.next} sendMessage={props.sendMessage}>
        <Option to="glue" text="chat.options.about-glue" />
        <Option to="astro" text="chat.options.about-you" />
        <Option to="contact" text="chat.options.contact" />
      </MessageOptions>
    </div>
  )
}

Works.propTypes = {
  next: PropTypes.string,
  message: PropTypes.object,
  sendMessage: PropTypes.func.isRequired
}

export default Works
