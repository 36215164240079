import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import ShipLink from '../../components/ShipLink'
import Projects from '../../components/Projects'
import parallastic from '../../utils/parallastic'
import './Unleash.sass'

export class Unleash extends React.Component {
  static propTypes = {
    pageName: PropTypes.string
  }

  static defaultProps = {
    pageName: 'Unleash'
  }

  componentDidMount = () => {
    const p = parallastic(this.scrollTarget())
    p.addToggler(this.refs.mobile, 'into-view', 0.40)
    p.addToggler(this.refs.ball, 'into-view', 0.40)
    p.addToggler(this.refs.watch, 'into-view', 0.40)
    p.addToggler(this.refs.speaking, 'into-view', 0.40)

    p.addToggler(this.refs.section, 'into-view', 0.25)
  }

  scrollTarget = () =>
    this.refs.unleash ? this.refs.unleash.parentNode.parentNode : false

  render = () => {
    return (
      <div className="page" id={this.props.pageName} ref="unleash">
        <section id="intro">
          <div className="container">
            <div id="ball-1" className="ball" />
            <div id="ball-2" className="ball" />
            <h1 className="page-title">
              {this.props.pageName}
            </h1>
            <div className="intro-text">
              <p>
                <FormattedMessage id="unleash.intro-text-1" />
              </p>
            </div>
          </div>
        </section>
        <section className="features" id="feature-1">
          <div className="left">
            <h2 className="title">
              <FormattedMessage id="unleash.feature-1-title" />
            </h2>
            <p>
              <FormattedMessage id="unleash.feature-1-text" />
            </p>
          </div>
          <div className="right" ref="mobile" />
        </section>
        <section className="features" id="feature-2">
          <div className="left">
            <h2 className="title">
              <FormattedMessage id="unleash.feature-2-title" />
            </h2>
            <p>
              <FormattedMessage id="unleash.feature-2-text-1" />
            </p>
            <p>
              <FormattedMessage id="unleash.feature-2-text-2" />
            </p>
          </div>
          <div className="right" ref="ball" />
        </section>
        <section className="features" id="feature-3" ref="section">
          <div className="container">
            <div className="left">
              <div className="feature-3-icon" ref="watch" />
              <h3 className="feature-3-title-1">
                <FormattedMessage id="unleash.feature-3-title-1" />
              </h3>
              <p>
                <FormattedMessage id="unleash.feature-3-text-1" />
              </p>
            </div>
            <div className="right">
              <div className="feature-3-icon" ref="speaking" />
              <h3 className="feature-3-title-2">
                <FormattedMessage id="unleash.feature-3-title-2" />
              </h3>
              <p>
                <FormattedMessage id="unleash.feature-3-text-2" />
              </p>
            </div>
          </div>
        </section>
        <section className="features" id="feature-4" />
        <ShipLink
          pageName="unleash"
          link="http://unleash.byglue.me/"
        />
        <Projects prev="ebay_social" next="ministerio_consumo" />
      </div>
    )
  }
}

export default Unleash
