import PropTypes from 'prop-types'
import React from 'react'
import Scroll from 'scroll'

import './TimedMessage.sass'

export class TimedMessage extends React.Component {
  static propTypes = {
    'className': PropTypes.string,
    'children': PropTypes.element,
    'duration': PropTypes.number,
    'startAt': PropTypes.number,
    'idx': PropTypes.number
  }

  static defaultProps = {
    'duration': 2000,
    'startAt': 0,
    'idx': 0
  }

  componentDidMount = () => {
    this.updateStatus(true)
  }

  hackishlySetOpacities = () => {
    const victims = document.querySelectorAll('.timed-message')
    const parent = document.querySelector('.message-list')
    for (const victim of victims) {
      const bottomDist = parent.scrollHeight - victim.offsetTop + parent.offsetTop
      const bottomPercent = bottomDist / parent.clientHeight
      victim.style.opacity = 2 - 2 * bottomPercent
    }
  }

  updateStatus = (skipRender) => {
    if (!skipRender) this.forceUpdate()
    const elem = document.querySelector('.message-list')
    Scroll.top(elem, elem.scrollHeight - elem.clientHeight, { duration: 500 })
    this.hackishlySetOpacities()

    const now = Date.now()
    const startAt = this.props.startAt + this.props.duration * this.props.idx
    const delta = startAt - now
    if (delta > 0) {
      this.timer = setTimeout(this.updateStatus, delta)
    } else if (delta > -this.props.duration) {
      this.timer = setTimeout(this.updateStatus, delta + this.props.duration)
    } else {
      this.timer = null
    }
  }

  componentWillUnmount = () => {
    if (this.timer) clearTimeout(this.timer)
  }

  render = () => {
    const now = Date.now()
    const startAt = this.props.startAt + this.props.duration * this.props.idx
    const delta = startAt - now
    if (delta > 0) return false

    let className = 'timed-message animation '
    if (this.props.className) className += this.props.className + ' '
    if (delta > -this.props.duration) {
      className += 'active'
    } else {
      className += 'finished'
    }

    return (
      <div className={className}>
        <div className="loader">
          <div className="dot dot1" />
          <div className="dot dot2" />
          <div className="dot dot3" />
          <div className="dot dot4" />
        </div>
        <div className="afterload">
          {this.props.children}
        </div>
      </div>
    )
  }

}

export default TimedMessage
