import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import Navigation from '../../components/Navigation'
import StickToTop from '../../components/StickToTop'
import parallastic from '../../utils/parallastic'

import './Home.sass'

const clients = [
  'kfc', 'igape', 'emf', 'carrefour', 'atat', 'mondelez', 'xunta', 'axa'
]

export class Home extends React.Component {
  static propTypes = {
    pageName: PropTypes.string
  }

  static defaultProps = {
    pageName: 'Home'
  }

  componentDidMount = () => {
    const p = parallastic(this.scrollTarget())
    p.addToggler(this.refs.creando.parentNode, 'into-view', 0.30)
    p.addToggler(this.refs.hearthy.parentNode, 'into-view', 0.20)
    p.addToggler(this.refs.fundivisa.parentNode, 'into-view', 0.25)
    p.addToggler(this.refs.unleash.parentNode, 'into-view', 0.25)
    p.addToggler(this.refs.kfcmexico.parentNode, 'into-view', 0.25)
    p.addToggler(this.refs.ebaySocial.parentNode, 'into-view', 0.25)
    p.addToggler(this.refs.listaRobinson.parentNode, 'into-view', 0.35)
    p.addToggler(this.refs.ministerioConsumo.parentNode, 'into-view', 0.25)

    p.addToggler(this.refs.graphic, 'into-view', 0.35)

    p.addToggler(this.refs.astronauta, 'into-view', 0.25)

    p.addToggler(this.refs.some, 'into-view', 0.20)
    p.addToggler(this.refs.kfc, 'into-view', 0.20)
    p.addToggler(this.refs.igape, 'into-view', 0.20)
    p.addToggler(this.refs.carrefour, 'into-view', 0.20)
    p.addToggler(this.refs.emf, 'into-view', 0.20)
    p.addToggler(this.refs.atat, 'into-view', 0.18)
    p.addToggler(this.refs.mondelez, 'into-view', 0.18)
    p.addToggler(this.refs.xunta, 'into-view', 0.18)
    p.addToggler(this.refs.axa, 'into-view', 0.18)

    p.addToggler(this.refs.where, 'into-view', 0.20)
    p.addToggler(this.refs.vigo, 'into-view', 0.20)
    p.addToggler(this.refs.madrid, 'into-view', 0.20)
    p.addToggler(this.refs.lisboa, 'into-view', 0.20)
    p.addToggler(this.refs.saintEtienne, 'into-view', 0.20)

    // Manage initial scroll if hash - hack-ish!
    if (window.location.hash.length) {
      const id = window.location.hash.substring(1)
      const elem = document.getElementById(id)
      if (elem) {
        window.setTimeout(() => {
          this.scrollTarget().scrollTop = elem.offsetTop
        }, 1)
      }
    }
  }

  scrollTarget = () =>
    this.refs.home ? this.refs.home.parentNode.parentNode : false

  render = () => {
    const clientsList = clients.map((client, i) => (
      <li key={i} ref={client} className={'client ' + client} />
    ))

    const stickyBar = (
      <Navigation key="nav" />
    )

    const ctx = require.context('src/static/assets/images/animations/', true, /.(webp|png)$/)

    return (
      <div id="home" ref="home">
        <div className="container">
          <Link to="/eu-funds" className="ribon" />
        </div>
        <StickToTop scrollTarget={this.scrollTarget} alt={stickyBar} offset={70}>
          <section id="intro">
            <div className="container">
              <div className="logo" />
              <h1 className="intro-text">
                <FormattedMessage id="home.intro-text" />
              </h1>
            </div>
          </section>
        </StickToTop>
        <section id="projects">
          <h2 className="projects-title">
            <span className="normal">
              <FormattedMessage id="home.projects-title-1" />
            </span>
            <strong><FormattedMessage id="home.projects-title-2" /></strong>
          </h2>

          <div className="projects-grid-2">
            <Link to="ebay_social" className="project ebaySocial">
              <picture>
                <source
                  srcSet={ctx('./ebay-animation.webp')}
                  type="image/webp"
                />
                <img
                  src={ctx('./ebay-animation.png')}
                  alt="ebay-project"
                />
              </picture>
              <div className="name" ref="ebaySocial">
                <FormattedMessage id="nav.ebaySocial" />
                <div className="explain">
                  <FormattedMessage id="nav.ebaySocial.explain" />
                </div>
              </div>
            </Link>
            <Link to="unleash" className="project unleash">
              <picture>
                <source
                  srcSet={ctx('./unleash-animation.webp')}
                  type="image/webp"
                />
                <img
                  src={ctx('./unleash-animation.png')}
                  alt="unleash-project"
                />
              </picture>
              <div className="name" ref="unleash">
                <FormattedMessage id="nav.unleash" />
                <div className="explain">
                  <FormattedMessage id="nav.unleash.explain" />
                </div>
              </div>
            </Link>
            <Link to="ministerio_consumo" className="project MinisterioConsumo">
              <picture>
                <source
                  srcSet={ctx('./ministerioconsumo-animation.webp')}
                  type="image/webp"
                />
                <img
                  src={ctx('./ministerioconsumo-animation.png')}
                  alt="ministerioConsumo-project"
                />
              </picture>
              <div className="name" ref="ministerioConsumo">
                <FormattedMessage id="nav.ministerioConsumo" />
                <div className="explain">
                  <FormattedMessage id="nav.ministerioConsumo.explain" />
                </div>
              </div>
            </Link>
          </div>

          <div className="projects">
            <Link to="kfc" className="project kfc">
              <picture>
                <source
                  srcSet={ctx('./kfc-animation.webp')}
                  type="image/webp"
                />
                <img
                  src={ctx('./kfc-animation.png')}
                  alt="kfc-project"
                />
              </picture>
              <div className="name" ref="kfcmexico">
                <FormattedMessage id="nav.kfc" />
                <div className="explain">
                  <FormattedMessage id="nav.kfc.explain" />
                </div>
              </div>
            </Link>
            <div className="project-wrapper-1">
              <Link to="lista_robinson" className="project ListaRobinson">
                <picture>
                  <source
                    srcSet={ctx('./listarobinson-animation.webp')}
                    type="image/webp"
                  />
                  <img
                    src={ctx('./listarobinson-animation.png')}
                    alt="listaRobinson-project"
                  />
                </picture>
                <div className="name" ref="listaRobinson">
                  <FormattedMessage id="nav.listaRobinson" />
                  <div className="explain">
                    <FormattedMessage id="nav.listaRobinson.explain" />
                  </div>
                </div>
              </Link>
              <Link to="/creando_sabores" className="project creando">
                <picture>
                  <source
                    srcSet={ctx('./creando-animation.webp')}
                    type="image/webp"
                  />
                  <img
                    src={ctx('./creando-animation.png')}
                    alt="creando_sabores-project"
                  />
                </picture>
                <div className="name" ref="creando">
                  <FormattedMessage id="nav.creando" />
                  <div className="explain">
                    <FormattedMessage id="nav.creando.explain" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="project-wrapper-2">
              <Link to="/hearthy" className="project hearthy">
                <picture>
                  <source
                    srcSet={ctx('./hearthy-animation.webp')}
                    type="image/webp"
                  />
                  <img
                    src={ctx('./hearthy-animation.png')}
                    alt="hearthy-project"
                  />
                </picture>
                <div className="name" ref="hearthy">
                  <FormattedMessage id="nav.hearthy" />
                  <div className="explain">
                    <FormattedMessage id="nav.hearthy.explain" />
                  </div>
                </div>
              </Link>
              <Link to="/fundivisa" className="project fundivisa">
                <picture>
                  <source
                    srcSet={ctx('./fundivisa-animation.webp')}
                    type="image/webp"
                  />
                  <img
                    src={ctx('./fundivisa-animation.png')}
                    alt="fundivisa-project"
                  />
                </picture>
                <div className="name" ref="fundivisa">
                  <FormattedMessage id="nav.fundivisa" />
                  <div className="explain">
                    <FormattedMessage id="nav.fundivisa.explain" />
                  </div>
                </div>
              </Link>
            </div>
          </div>

        </section>
        <section id="agency">
          <div className="container">
            <div className="split">
              <div className="item left">
                <h2 className="title">
                  <strong>
                    <FormattedMessage id="home.agency-title-1" />
                  </strong>
                  <span>
                    <FormattedMessage id="home.agency-title-2" />
                  </span>
                  <strong>
                    <FormattedMessage id="home.agency-title-3" />
                  </strong>
                </h2>
                <p>
                  <FormattedMessage id="home.agency-text-1" />
                </p>
                <p>
                  <FormattedMessage id="home.agency-text-2" />
                </p>
                <Link to="/stack" className="link">
                  <MarkdownMessage id="know-about-stack" />
                  <span className="icon icon-next" />
                </Link>
              </div>
              <div className="item right">
                <div className="animation" ref="graphic" />
              </div>
            </div>
          </div>
        </section>

        <section id="job-offers">
          <div className="container">
            <div className="split">
              <div className="item left">
                <h1 className="title">
                  <FormattedMessage id="home.job-offers.title" />
                </h1>
                <p>
                  <FormattedMessage id="home.job-offers.text" />
                </p>
                <Link to="/jobs" className="link">
                  <MarkdownMessage id="home.job-offers.about-link" />
                  <span className="icon icon-next" />
                </Link>
              </div>
              <div className="item right">
                <div className="astronauta" ref="astronauta" />
              </div>
            </div>
          </div>
        </section>

        <section id="clients">
          <div className="container">
            <h2 className="clients-title" ref="some">
              <MarkdownMessage id="home.clients-title" />
            </h2>
            <ul>
              {clientsList}
            </ul>
          </div>
        </section>

        <section id="offices">
          <div className="container">
            <h2 className="offices-title" ref="where">
              <MarkdownMessage id="home.offices-title" />
            </h2>
            <ul className="address">
              <li className="office vigo" ref="vigo">
                <div className="city">
                  <h3>Vigo</h3>
                </div>
                <div className="address">
                  <div className="street">
                    López de Neira 3, Oficina 212, 36202 Vigo
                  </div>
                  <div className="phone">
                    <a href="tel:+34886128886">
                      +34 886 128 886
                    </a>
                  </div>
                  <div className="mail">
                    <a href="mailto:hello@glue.digital?Subject=Hello%20again">
                      hello@glue.digital
                    </a>
                  </div>
                </div>
              </li>
              <li className="office madrid" ref="madrid">
                <div className="city">
                  <h3>Madrid</h3>
                </div>
                <div className="address">
                  <div className="street">
                    Calle Eloy Gonzalo, 27 28010 Madrid
                  </div>
                  <div className="mail">
                    <a href="mailto:hello@glue.digital?Subject=Hello%20again">
                      hello@glue.digital
                    </a>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="address">
              <li className="office lisboa" ref="lisboa">
                <div className="city">
                  <h3>Lisboa</h3>
                </div>
                <div className="address">
                  <div className="street">
                    3º Rua da Alegria 122B, 1250-096
                  </div>
                  <div className="mail">
                    <a href="mailto:hello@glue.digital?Subject=Hello%20again">
                      hello@glue.digital
                    </a>
                  </div>
                </div>
              </li>
              <li className="office saint-etienne" ref="saintEtienne">
                <div className="city">
                  <h3>Saint-Etienne</h3>
                </div>
                <div className="address">
                  <div className="street">
                    35, Rue des frères Ponchardier 42100
                  </div>
                  <div className="mail">
                    <a href="mailto:hello@glue.digital?Subject=Hello%20again">
                      hello@glue.digital
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    )
  }
}

export default Home
