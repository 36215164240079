import { connect } from 'react-redux'
import * as chatActions from '../../../store/actions/chat'
import * as infoActions from '../../../store/actions/info'
import MessageList from '../components/MessageList'

const mapStateToProps = (state) => {
  return {
    messages: state.chat.messages,
    info: state.info
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendMessage: (type) => dispatch(chatActions.addMessage(type)),
    saveInfo: (key, value) => dispatch(infoActions.save(key, value))
  }
}

const VisibleMessageList = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageList)

export default VisibleMessageList
