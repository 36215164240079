import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import ShipLink from '../../components/ShipLink'
import Projects from '../../components/Projects'
import parallastic from '../../utils/parallastic'
import './Kfc.sass'

export class Kfc extends React.Component {
  static propTypes = {
    pageName: PropTypes.string
  }

  static defaultProps = {
    pageName: 'Kfc'
  }

  componentDidMount = () => {
    const p = parallastic(this.scrollTarget())
    p.addToggler(this.refs.laptop, 'into-view', 0.40)
    p.addToggler(this.refs.mobile, 'into-view', 0.40)
    p.addToggler(this.refs.watch, 'into-view', 0.40)
    p.addToggler(this.refs.speaking, 'into-view', 0.40)

    p.addToggler(this.refs.section, 'into-view', 0.25)
  }

  scrollTarget = () =>
    this.refs.kfc ? this.refs.kfc.parentNode.parentNode : false

  render = () => {
    return (
      <div className="page" id={this.props.pageName} ref="kfc">
        <section id="intro">
          <div className="container">
            <h1 className="page-title">
              <FormattedMessage id="kfc.page-title" />
            </h1>
            <div className="intro-text">
              <p>
                <FormattedMessage id="kfc.intro-text-1" />
              </p>
            </div>
          </div>
        </section>
        <section className="features" id="feature-1">
          <div className="left">
            <h2 className="title">
              <FormattedMessage id="kfc.feature-1-title" />
            </h2>
            <p>
              <FormattedMessage id="kfc.feature-1-text" />
            </p>
          </div>
          <div className="right" ref="laptop" />
        </section>
        <section className="features" id="feature-2">
          <div className="left">
            <h2 className="title">
              <FormattedMessage id="kfc.feature-2-title" />
            </h2>
            <p>
              <FormattedMessage id="kfc.feature-2-text" />
            </p>
          </div>
          <div className="video-wrapper right" ref="mobile">
            <video autoPlay loop muted playsInline className="video">
              <source src="/assets/video/kfc.webm" type="video/webm" codecs="vp9" />
              <source src="/assets/video/kfc.mp4" type="video/mp4" />
            </video>
          </div>
        </section>
        <section className="features" id="feature-3" ref="section">
          <div className="container">
            <div className="left">
              <div className="feature-3-icon" ref="watch" />
              <h3 className="feature-3-title-1">
                <FormattedMessage id="kfc.feature-3-title-1" />
              </h3>
              <p>
                <FormattedMessage id="kfc.feature-3-text-1" />
              </p>
            </div>
            <div className="right">
              <div className="feature-3-icon" ref="speaking" />
              <h3 className="feature-3-title-2">
                <FormattedMessage id="kfc.feature-3-title-2" />
              </h3>
              <p>
                <FormattedMessage id="kfc.feature-3-text-2" />
              </p>
            </div>
          </div>
        </section>
        <section className="features" id="feature-4" />
        <ShipLink
          pageName="kfc"
          link="http://www.kfc.com.mx/"
        />
        <Projects prev="ministerio_consumo" next="lista_robinson" />
      </div>
    )
  }
}

export default Kfc
