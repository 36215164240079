import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import TimedMessage from './TimedMessage'
import './MessageInput.sass'

export const MessageInput = (props, context) => {
  let handleCancel = (e) => {
    e.preventDefault()
    props.sendMessage(props.back)
  }
  let handleContinue = (e) => {
    e.preventDefault()
    props.saveInfo(props.name, e.target.input.value)
    props.sendMessage(props.to)
  }

  let prompt = false
  if (props.prompt) {
    prompt = (
      <span className="prompt">
        <FormattedMessage id={props.prompt} />
      </span>
    )
  }

  let cancel = false
  let cancelClassName = 'cancel'
  if (props.next) cancelClassName += ' disabled'
  if (props.cancel && (!props.next || props.next === props.back)) {
    cancel = (
      <div className={cancelClassName} onClick={handleCancel}>
        <FormattedMessage id={props.cancel} />
      </div>
    )
  }

  let placeholder
  if (props.placeholder) {
    placeholder = context.intl.formatMessage({ id: props.placeholder })
  }

  let form = false
  if (!props.next || props.next === props.to) {
    form = (
      <form onSubmit={handleContinue}>
        {prompt}
        <input type="text" name="input" placeholder={placeholder}
          disabled={props.next} defaultValue={props.info[props.name]} />
        <button type="submit" disabled={props.next}>
          <span className="icon icon-next" />
        </button>
      </form>
    )
  }

  return (
    <TimedMessage startAt={props.startAt} idx={props.idx} className="timed-input">
      <div className="input">
        {form}
        {cancel}
      </div>
    </TimedMessage>
  )
}

MessageInput.propTypes = {
  'back': PropTypes.string,
  'to': PropTypes.string,
  'next': PropTypes.string,
  'cancel': PropTypes.string,
  'placeholder': PropTypes.string,
  'prompt': PropTypes.string,
  'name': PropTypes.string,
  'info': PropTypes.object,
  'saveInfo': PropTypes.func.isRequired,
  'sendMessage': PropTypes.func.isRequired,
  'startAt': PropTypes.number,
  'idx': PropTypes.number
}

MessageInput.contextTypes = {
  'intl': PropTypes.object.isRequired
}

export default MessageInput
