import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import { Link } from 'react-router'
import data from 'src/data'

import './Jobs.sass'

export class Jobs extends React.Component {
  static propTypes = {
    pageName: PropTypes.string
  }

  static defaultProps = {
    pageName: 'Jobs'
  }

  render = () => {
    const details = ['where', 'better', 'work', 'product', 'grow']
    return (
      <div className="page" id={this.props.pageName}>
        <section id="intro">
          <div className="container">
            <div className="planet" />
            <div className="split">
              <div className="item left">
                <h1>
                  <FormattedMessage id="jobs.intro.title" />
                </h1>
                <FormattedMessage id="jobs.intro.text" />
              </div>
              <div className="item right" />
            </div>
          </div>
        </section>
        <section id="details">
          {details.map((detail, i) => {
            return (
              <div id={'detail-' + (i + 1)} className={'detail ' + detail}>
                <div className="container">
                  <div className="split">
                    <div className="item left">
                      <div className="image" />
                    </div>
                    <div className="item right">
                      <h2>
                        <FormattedMessage id={'jobs.details.detail-' + (i + 1) + '.title'} />
                      </h2>
                      <FormattedMessage id={'jobs.details.detail-' + (i + 1) + '.text'}>
                        {txt => txt.split('\n').map((l, j) => <p key={j}>{l}</p>)}
                      </FormattedMessage>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </section>
        <a id="link-offer" href="#offers">
          <MarkdownMessage id="jobs.link-offer" />
          <span className="icon icon-down" />
        </a>
        <section id="offers">
          <div className="container">
            <h2><FormattedMessage id="jobs.jobs.title" /></h2>
            <div className="jobs-wrapper">
              {data.jobs.map((job) => {
                const path = require('src/static/assets/images/pages/job/' + job.slug + '-card.png')
                return (
                  <Link to={'/jobs/' + job.slug} key={job.slug} className={'job ' + job.slug}>
                    <div className="card">
                      <div className="job-image">
                        <img src={path} />
                      </div>
                      <div className="job-data">
                        <div className="top-wrapper">
                          <h1>
                            <FormattedMessage id={'jobs.job.title.' + job.slug} />
                          </h1>
                          <p>
                            <FormattedMessage id={'jobs.job.text.' + job.slug} />
                          </p>
                        </div>
                        <div className="bottom-wrapper">
                          <div className="tags">
                            {job.tags.map((tag, i) => {
                              return <div className="tag" key={i}>{tag.name}</div>
                            })}
                          </div>
                          <div className="link">
                            <FormattedMessage id="know-more" />
                            <span className="icon icon-next" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Jobs
