import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import TimedMessage from '../TimedMessage'
import { MessageOptions, Option } from '../MessageOptions'

export const Glue = (props) => {
  const ts = props.message.timestamp
  return (
    <div className="message message-glue">
      <div className="body">
        <TimedMessage startAt={ts} idx={0}>
          <FormattedMessage id="chat.glue.intro-1" />
        </TimedMessage>
        <TimedMessage startAt={ts} idx={1}>
          <FormattedMessage id="chat.glue.intro-2" />
        </TimedMessage>
        <TimedMessage startAt={ts} idx={2}>
          <FormattedMessage id="chat.glue.intro-3" />
        </TimedMessage>
        <TimedMessage startAt={ts} idx={3}>
          <FormattedMessage id="chat.glue.intro-4" />
        </TimedMessage>
      </div>

      <MessageOptions prompt="chat.options.something-else" startAt={ts} idx={4}
        next={props.next} sendMessage={props.sendMessage}>
        <Option to="works" text="chat.options.show-work" />
        <Option to="astro" text="chat.options.about-you" />
        <Option to="contact" text="chat.options.contact" />
      </MessageOptions>
    </div>
  )
}

Glue.propTypes = {
  next: PropTypes.string,
  message: PropTypes.object,
  sendMessage: PropTypes.func.isRequired
}

export default Glue
