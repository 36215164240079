import PropTypes from 'prop-types'
import React from 'react'
import parallastic from '../../utils/parallastic'
import { FormattedMessage } from 'react-intl'
import './ShipLink.sass'

export class ShipLink extends React.Component {

  static propTypes = {
    pageName: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  }

  componentDidMount = () => {
    const p = parallastic(this.scrollTarget())
    p.addToggler(this.refs.ship, 'into-view', 0.40)
  }

  scrollTarget = () =>
    this.refs.shipLink ? this.refs.shipLink.parentNode : false

  render = () => {
    const pageName = this.props.pageName
    const link = this.props.link

    const linkText = 'shipLink.' + pageName

    return (
      <div id="shipLink" className={pageName} ref="shipLink">
        <div className="container">
          <div className="ship" ref="ship" />
          <div className="link">
            <h3>
              <FormattedMessage id="shipLink.launch" />
            </h3>
            <a className="button" target="_blank" rel="noopener noreferrer" href={link}>
              <FormattedMessage id={linkText} />
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default ShipLink
