import * as infoActions from '../actions/info'

const initialState = {}

export const info = (state = initialState, action) => {
  switch (action.type) {

    case infoActions.INFO_SAVE:
      const ret = Object.assign({}, state)
      ret[action.key] = action.value
      return ret

    default:
      return state
  }
}

export default info
