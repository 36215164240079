import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import ShipLink from '../../components/ShipLink'
import Projects from '../../components/Projects'
import parallastic from '../../utils/parallastic'
import './MinisterioConsumo.sass'

import grid1 from 'src/static/assets/images/pages/ministerioconsumo/grid-1.png'
import grid2 from 'src/static/assets/images/pages/ministerioconsumo/grid-2.png'
import grid3 from 'src/static/assets/images/pages/ministerioconsumo/grid-3.png'
import grid4 from 'src/static/assets/images/pages/ministerioconsumo/grid-4.png'
import grid5 from 'src/static/assets/images/pages/ministerioconsumo/grid-5.png'
import grid6 from 'src/static/assets/images/pages/ministerioconsumo/grid-6.png'
import grid7 from 'src/static/assets/images/pages/ministerioconsumo/grid-7.png'
import grid8 from 'src/static/assets/images/pages/ministerioconsumo/grid-8.png'
import grid9 from 'src/static/assets/images/pages/ministerioconsumo/grid-9.png'
import grid10 from 'src/static/assets/images/pages/ministerioconsumo/grid-10.png'

export class MinisterioConsumo extends React.Component {
  static propTypes = {
    pageName: PropTypes.string
  }

  static defaultProps = {
    pageName: 'MinisterioConsumo'
  }

  componentDidMount = () => {
    const p = parallastic(this.scrollTarget())
    p.addToggler(this.refs.mobile, 'into-view', 0.40)
    p.addToggler(this.refs.users, 'into-view', 0.40)
    p.addToggler(this.refs.architecture, 'into-view', 0.40)

    p.addToggler(this.refs.section, 'into-view', 0.25)
    p.addToggler(this.refs.visual, 'into-view', 0.25)
    p.addToggler(this.refs.news, 'into-view', 0.25)
    p.addToggler(this.refs.logos, 'into-view', 0.25)
  }

  scrollTarget = () =>
    this.refs.ministerioConsumo ? this.refs.ministerioConsumo.parentNode.parentNode : false

  render = () => {
    return (
      <div className="page" id={this.props.pageName} ref="ministerioConsumo">
        <section id="intro">
          <div className="container">
            <h1 className="page-title">
              <FormattedMessage id="ministerioConsumo.page-title" />
            </h1>
            <h3>
              <FormattedMessage id="ministerioConsumo.page-subtitle" />
            </h3>
            <div className="intro-text">
              <p>
                <FormattedMessage id="ministerioConsumo.intro-text" />
              </p>
            </div>
          </div>
        </section>

        <section className="features" id="feature-1">
          <div className="left">
            <h1 className="page-title">
              <FormattedMessage id="ministerioConsumo.feature-1-title" />
            </h1>
            <p>
              <FormattedMessage id="ministerioConsumo.feature-1-text" />
            </p>
          </div>
          <div className="right" ref="mobile" />
        </section>

        <section className="features" id="feature-2" ref="section">
          <div className="container">
            <div className="left">
              <div className="feature-2-icon" ref="architecture" />
              <p>
                <FormattedMessage id="ministerioConsumo.feature-2-text-1" />
              </p>
            </div>
            <div className="right">
              <div className="feature-2-icon" ref="users" />
              <p>
                <FormattedMessage id="ministerioConsumo.feature-2-text-2" />
              </p>
            </div>
          </div>
        </section>

        <section className="features" id="feature-4" />

        <section className="features" id="comment">
          <div className="container">
            <h1>
              <FormattedMessage id="ministerioConsumo.comment-title" />
            </h1>
            <p>
              <FormattedMessage id="ministerioConsumo.comment-text" />
            </p>
          </div>
        </section>

        <section className="features" id="feature-5" ref="visual">
          <div className="container">
            <div className="left">
              <video
                width="100%"
                height="100%"
                controls
              >
                <source src="/assets/video/ministerioconsumo-1.webm" type="video/webm" codecs="vp9" />
                <source src="/assets/video/ministerioconsumo-1.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="right">
              <video
                width="100%"
                height="100%"
                controls
              >
                <source src="/assets/video/ministerioconsumo-2.webm" type="video/webm" codecs="vp9" />
                <source src="/assets/video/ministerioconsumo-2.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </section>

        <section className="features" id="feature-6" ref="news">
          <div className="container">
            <div className="left">
              <div className="card">
                <div className="card-container">
                  <p>
                    <MarkdownMessage id="ministerioConsumo.card-1-1" />
                  </p>
                  <p>
                    <a
                      href="https://cincodias.elpais.com/cincodias/2020/06/04/economia/1591255913_171971.html"
                      target="_blank" rel="noopener noreferrer"
                    >
                      <MarkdownMessage id="ministerioConsumo.card-1-2" />
                    </a>
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="card-container">
                  <p>
                    <MarkdownMessage id="ministerioConsumo.card-2-1" />
                  </p>
                  <p>
                    <a
                      href="https://www.antena3.com/noticias/economia/consumo-lanza-web-responder-dudas-afectados-cancelaciones-estado-alarma_202006045ed8e5cbe1c24d0001a96c70.html"
                      target="_blank" rel="noopener noreferrer"
                    >
                      <MarkdownMessage id="ministerioConsumo.card-2-2" />
                    </a>
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="card-container">
                  <p>
                    <MarkdownMessage id="ministerioConsumo.card-3-1" />
                  </p>
                  <p>
                    <a
                      href="https://twitter.com/carreteromolero/status/1270305005833158657"
                      target="_blank" rel="noopener noreferrer"
                    >
                      <MarkdownMessage id="ministerioConsumo.card-3-2" />
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="card">
                <div className="card-container">
                  <p>
                    <MarkdownMessage id="ministerioConsumo.card-4-1" />
                  </p>
                  <p>
                    <a
                      href="https://www.rtve.es/noticias/20200604/asi-web-para-consumidores-afectados-durante-estado-alarma-como-donde-cuando-puedo-reclamar/2015526.shtml"
                      target="_blank" rel="noopener noreferrer"
                    >
                      <MarkdownMessage id="ministerioConsumo.card-4-2" />
                    </a>
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="card-container">
                  <p>
                    <MarkdownMessage id="ministerioConsumo.card-5-1" />
                  </p>
                  <p>
                    <a
                      href="https://hipertextual.com/2020/06/consumidorescovid19"
                      target="_blank" rel="noopener noreferrer"
                    >
                      <MarkdownMessage id="ministerioConsumo.card-5-2" />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="features" id="logos">
          <div className="container">
            <h3>
              <MarkdownMessage id="ministerioConsumo.logos-title" />
            </h3>
            <div className="service-grid-elements">
              <div className="service-grid-item">
                <img src={grid1} alt="CincoDías" />
              </div>
              <div className="service-grid-item">
                <img src={grid2} alt="ElPaís" />
              </div>
              <div className="service-grid-item">
                <img src={grid3} alt="LaSexta" />
              </div>
              <div className="service-grid-item">
                <img src={grid4} alt="CincoDías" />
              </div>
              <div className="service-grid-item">
                <img src={grid5} alt="rtve" />
              </div>
              <div className="service-grid-item">
                <img src={grid6} alt="ElMundo" />
              </div>
              <div className="service-grid-item">
                <img src={grid7} alt="Expansion" />
              </div>
              <div className="service-grid-item">
                <img src={grid8} alt="20Minutos" />
              </div>
              <div className="service-grid-item gap1">
                <img src={grid9} alt="Antena3" />
              </div>
              <div className="service-grid-item gap2">
                <img src={grid10} alt="CadenaSer" />
              </div>
            </div>
          </div>
        </section>

        <ShipLink
          pageName="ministerioConsumo"
          link="http://consumidorescovid19.gob.es/"
        />
        <Projects prev="unleash" next="kfc" />
      </div>
    )
  }
}

export default MinisterioConsumo
