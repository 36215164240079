import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Projects from '../../components/Projects'
import parallastic from '../../utils/parallastic'
import './Fundivisa.sass'

export class Fundivisa extends React.Component {
  static propTypes = {
    pageName: PropTypes.string
  }

  static defaultProps = {
    pageName: 'Fundivisa'
  }

  componentDidMount = () => {
    const p = parallastic(this.scrollTarget())
    p.addToggler(this.refs.efficiency, 'into-view', 0.40)
    p.addToggler(this.refs.outcomes, 'into-view', 0.40)

    p.addToggler(this.refs.section, 'into-view', 0.25)
  }

  scrollTarget = () =>
    this.refs.fundivisa ? this.refs.fundivisa.parentNode.parentNode : false

  render = () => {
    return (
      <div className="page" id={this.props.pageName} ref="fundivisa">
        <section id="intro">
          <div className="container">
            <h1 className="page-title">
              {this.props.pageName}
            </h1>
            <div className="intro-text">
              <p>
                <FormattedMessage id="fundi.intro-text-1" />
              </p>
              <p>
                <FormattedMessage id="fundi.intro-text-2" />
              </p>
            </div>
          </div>
        </section>
        <section className="features" id="feature-1">
          <div className="image">
            <div className="content" />
          </div>
          <h2 className="feature-1-title">
            <FormattedMessage id="fundi.feature-1-title" />
          </h2>
          <div className="container">
            <div className="left">
              <p>
                <FormattedMessage id="fundi.feature-1-text-1" />
              </p>
            </div>
            <div className="right">
              <p>
                <FormattedMessage id="fundi.feature-1-text-2" />
              </p>
            </div>
          </div>
        </section>
        <section className="features" id="feature-2" ref="section">
          <div className="container">
            <div className="left">
              <div className="feature-2-icon" ref="efficiency" />
              <h3 className="feature-2-title-1">
                <FormattedMessage id="fundi.feature-2-title-1" />
              </h3>
              <p>
                <FormattedMessage id="fundi.feature-2-text-1" />
              </p>
            </div>
            <div className="right">
              <div className="feature-2-icon" ref="outcomes" />
              <h3 className="feature-2-title-2">
                <FormattedMessage id="fundi.feature-2-title-2" />
              </h3>
              <p>
                <FormattedMessage id="fundi.feature-2-text-2" />
              </p>
            </div>
          </div>
        </section>
        <section className="features" id="feature-3">
          <div className="container">
            <div className="video">
              <video
                width="100%"
                height="100%"
                controls
                poster="/assets/video/poster.jpg"
              >
                <source src="/assets/video/fundivisa.webm" type="video/webm" codecs="vp9" />
                <source src="/assets/video/fundivisa.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </section>
        <Projects prev="hearthy" next="ebay_social" />
      </div>
    )
  }
}

export default Fundivisa
