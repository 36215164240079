import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import parallastic from '../../utils/parallastic'
import './Header.sass'

export class Header extends React.Component {

  static propTypes = {
    pageName: PropTypes.string.isRequired
  }

  componentDidMount = () => {
    const p = parallastic(this.scrollTarget())
    if (this.props.pageName === 'Medipremium') {
      p.addProportional(
        this.refs.img, 0, 100,
        'transform', 0, 50, {
          prefix: 'translateY(',
          suffix: 'px)',
          units: '%'
        }
      )
      p.addProportional(
        this.refs.header, 0, 100,
        'backgroundPosition', 40, 70, {
          prefix: 'center ',
          suffix: '%',
          units: '%'
        }
      )
    } else if (this.props.pageName === 'Fundivisa') {
      p.addProportional(
        this.refs.img, 0, 100,
        'transform', 0, 140, {
          prefix: 'translateY(',
          suffix: 'px)',
          units: '%'
        }
      )
      p.addProportional(
        this.refs.header, 0, 100,
        'backgroundPosition', 10, 170, {
          prefix: 'center ',
          suffix: '%',
          units: '%'
        }
      )
    } else {
      p.addProportional(
        this.refs.img, 0, 100,
        'transform', 0, 150, {
          prefix: 'translateY(',
          suffix: 'px)',
          units: '%'
        }
      )
    }
  }

  scrollTarget = () =>
    this.refs.header ? this.refs.header.parentNode : false

  render = () => {
    const pageName = this.props.pageName
    const ctx = require.context('src/static/assets/images/pages', true, /.(webp|png)$/)
    const prefix = './' + pageName.toLowerCase() + '/' + pageName.toLowerCase()
    let pageTitle = pageName
    if (pageName === 'Creando') {
      pageTitle = pageName + ' sabores'
    } else if (pageName === 'EbaySocial') {
      pageTitle = 'Ebay Social'
    } else if (pageName === 'EuFunds') {
      pageTitle = 'Fondos europeos'
    } else if (pageName === 'ListaRobinson') {
      pageTitle = 'Lista Robinson'
    } else if (pageName === 'MinisterioConsumo') {
      pageTitle = 'Asistente Covid - 19'
    } else if (pageName === 'Workshops') {
      pageTitle = <MarkdownMessage id="header.workshops" />
    } else if (pageName === 'Stack') {
      pageTitle = <MarkdownMessage id="header.stack" />
    } else if (pageName === 'Jobs') {
      pageTitle = <MarkdownMessage id="header.jobs" />
    } else if (pageName === 'Job') {
      pageTitle = <MarkdownMessage id="header.job" />
    }

    let headerImage = false
    if (pageName !== 'Legal' && pageName !== 'NotFound' && pageName !== 'Stack' &&
      pageName !== 'EuFunds' && pageName !== 'Job' && pageName !== 'Jobs' && pageName !== 'Workshops') {
      const headerImageWebp = ctx(prefix + '-1.webp')
      const headerImagePng = ctx(prefix + '-1.png')

      headerImage =
        <picture className="header-image">
          <source
            srcSet={headerImageWebp}
            type="image/webp"
          />
          <img
            src={headerImagePng}
            alt={pageName + '-header'}
            ref="img"
          />
        </picture>
    }
    let backLink = '/#projects'
    if (pageName === 'Jobs') {
      backLink = '/#job-offers'
    } else if (pageName === 'Job') {
      backLink = '/jobs'
    } else if (pageName === 'Stack') {
      backLink = '/#agency'
    }

    return (
      <header id="header" className={pageName} ref="header">
        <nav id="pages-navigation">
          <div className="container">
            <Link to={backLink}>
              <span className="icon icon-back" />
              <span>
                <FormattedMessage id="header.project" />
              </span>
              <strong>
                {pageTitle}
              </strong>
            </Link>
          </div>
        </nav>
        {headerImage}
      </header>
    )
  }
}

export default Header
