import * as chatActions from '../actions/chat'

const initialState = { messages: [] }

export const chat = (state = initialState, action) => {
  switch (action.type) {

    case chatActions.CHAT_ADD_MESSAGE:
      const messages = state.messages ? state.messages.slice() : []
      messages.push(action.message)
      return Object.assign({}, state, {
        messages: messages
      })

    case chatActions.CHAT_UPDATE_ADD_MESSAGE:
      if (state.messages && state.messages.length) {
        const messages = state.messages ? state.messages.slice() : []
        const last = messages[messages.length - 1]
        messages[messages.length - 1] = Object.assign(last, action.patch)
        messages.push(action.message)
        return Object.assign({}, state, {
          messages: messages
        })
      }
      return state

    case chatActions.CHAT_CLEAR:
      return Object.assign({}, state, initialState)

    default:
      return state
  }
}

export default chat
